import { ctApi } from '@/api';

export type Fact = {
  pdv?: number;
  quantity?: number;
  unit?: string;
};

export type FactSet = {
  addedSugars?: Fact;
  calcium?: Fact;
  calories?: Fact;
  caloriesFromFat?: Fact;
  cholesterol?: Fact;
  dietaryFiber?: Fact;
  iron?: Fact;
  protein?: Fact;
  potassium?: Fact;
  saturatedFat?: Fact;
  sodium?: Fact;
  totalCarbohydrate?: Fact;
  totalFat?: Fact;
  totalSugars?: Fact;
  transFat?: Fact;
  vitaminA?: Fact;
  vitaminC?: Fact;
  vitaminD?: Fact;
};

export interface NutritionFacts {
  perServing: FactSet;
  servingSize?: Required<Fact> & {
    description?: string;
  };
}

export interface ProductNutritionFactsResponse {
  key: string;
  value: NutritionFacts;
}

export async function productNutritionFacts(
  productKey: string,
): Promise<ProductNutritionFactsResponse> {
  const {
    body: { key, value },
  } = await ctApi
    .customObjects()
    .withContainerAndKey({ container: 'productNutritionFacts', key: productKey })
    .get()
    .execute();

  return { key, value };
}
