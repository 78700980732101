<script lang="ts" setup>
import { computed } from 'vue';

import { NutritionFacts as NutritionFactsType } from '@/api/productNutritionFacts';
import { TagReference } from '@/api/productTagReferences';
import Gallery, {
  CarouselItem,
  GalleryDataPromo,
} from '@/components/base/image-gallery/Gallery.vue';
import TagBadge from '@/components/base/TagBadge.vue';
import BagSizeBadge from '@/components/pdp/BagSizeBadge.vue';
import GalleryProductDetails from '@/components/pdp/GalleryProductDetails.vue';
import OutOfStockBadge from '@/components/pdp/OutOfStockBadge.vue';
import { useFeatureFlags } from '@/composables/useFeatureFlags';
import { TypographyTags } from '@/utils/accessibility';
import { ImageBySize } from '@/utils/image';
import { isDefined } from '@/utils/isDefined';
import { NutsProductVariant } from '@/utils/product';

const PACKAGE_SIZE_IMAGE_INDEX = 1;

interface PackageSizeImage {
  alt?: string;
  image: string;
  miniature?: string;
  type: string;
}

const props = defineProps<{
  dataPromos?: GalleryDataPromo;
  headerTag?: TypographyTags;
  images: ImageBySize[];
  isGift?: boolean;
  nutritionFacts?: NutritionFactsType;
  productName?: string;
  tagNames: string[];
  tagsReferences?: TagReference[];
  titleImage?: ImageBySize;
  variant: NutsProductVariant;
}>();

const { flags } = useFeatureFlags();

const allPackageSizeImages: PackageSizeImage[] = [
  {
    image: 'https://nuts.com/images/pdp/2023/M.1194ef04.jpg',
    type: '1 Pound Bag - Medium',
  },
  {
    image: 'https://nuts.com/images/pdp/2023/W.15ebeb58.jpg',
    type: '5 Pound Bag - White',
  },
  {
    image: 'https://nuts.com/images/pdp/2023/S.a259144e.jpg',
    type: '1 Pound Bag - Small',
  },
  {
    image: 'https://nuts.com/images/pdp/2023/L.b0969f31.jpg',
    type: '1 Pound Bag - Large',
  },
  {
    image: 'https://nuts.com/images/pdp/2023/B.aeb90987.jpg',
    type: '5 Pound Bag - Brown',
  },
  {
    image: 'https://nuts.com/images/pdp/2023/LB.641dd1de.jpg',
    type: 'Large Black Bag',
  },
  {
    image: 'https://nuts.com/images/pdp/2023/SB.a4f53ccf.jpg',
    type: 'Small Black Bag',
  },
  {
    image: 'https://nuts.com/images/pdp/2023/TE.c447bb7d.jpg',
    type: '16 Ounce Tamper Evident Container',
  },
  {
    image: 'https://nuts.com/images/pdp/2023/SD.4f56384b.jpg',
    type: '24 Ounce Square Container',
  },
  {
    image: 'https://nuts.com/images/pdp/2023/RD.ddec36ed.jpg',
    type: '48 Ounce Round Container',
  },
];

const packageSizeImage = computed<PackageSizeImage | undefined>(() =>
  allPackageSizeImages.find((image) => image.type === props.variant.bagSize?.key),
);

const carouselSlides = computed<CarouselItem[]>(() => {
  const images: CarouselItem[] = props.images.map((image) => ({
    alt: `${props.productName} photo`,
    content: image.large,
    miniature: image.small,
    type: 'image',
  }));

  if (!props.isGift) {
    if (packageSizeImage.value) {
      images.splice(PACKAGE_SIZE_IMAGE_INDEX, 0, {
        alt: packageSizeImage.value.alt || `Bag size - ${props.productName} photo`,
        content: packageSizeImage.value.image,
        miniature: packageSizeImage.value.miniature || packageSizeImage.value.image,
        type: 'image',
      });
    }
    if (props.nutritionFacts) {
      const hasPdvOrQuantity = Object.values(props.nutritionFacts.perServing).some(
        (nutritionFact) => nutritionFact.pdv || nutritionFact.quantity,
      );

      if (hasPdvOrQuantity)
        images.push({
          content: GalleryProductDetails,
          miniature: 'https://nuts.com/images/pdp/2023/product_detail_thumbnail.1888e469.jpg',
          type: 'html',
        });
    }
  }

  return images;
});
</script>

<template>
  <Gallery
    :carouselSlides="carouselSlides"
    :dataPromos="dataPromos"
    :headerTag
    :title="productName"
  >
    <template v-slot="{ isMobile, slide, scale }">
      <Component
        :is="slide.content"
        :isMobile
        :nutritionFacts="isGift ? undefined : nutritionFacts"
        :productName
        :style="{ scale }"
        :tagsReferences
        :variant
      />
    </template>
    <template #badge="{ index }">
      <template v-if="!nutritionFacts || (nutritionFacts && index !== carouselSlides.length - 1)">
        <OutOfStockBadge
          v-if="variant.backordered"
          class="absolute top-0 z-10 sm:hidden -left-10"
        />
        <TagBadge
          v-if="!isDefined(flags.socialProofMessaging)"
          class="absolute top-0 right-4 ws-md:right-0 lg:top-1.5"
          :tags="tagNames"
        />
      </template>
      <transition name="fade">
        <BagSizeBadge
          v-if="packageSizeImage && variant.unitName && index === PACKAGE_SIZE_IMAGE_INDEX"
          :bagSize="packageSizeImage.type"
          class="absolute z-10 top-[15%] left-1/2 transform -translate-x-1/2 -translate-y-1/2 scale-50 xl:scale-75"
          :unitName="variant.unitName"
        />
      </transition>
    </template>
  </Gallery>
</template>
