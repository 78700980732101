<script lang="ts">
import { from } from '@nuts/auto-delivery-sdk/dist/utils/money';
import { computed, onMounted, ref } from 'vue';

import { browseProducts, getIndex, init as initAlgolia } from '@/api/algolia';
import GiftBoxShipment from '@/components/pdp-bundle/GiftBoxShipment.vue';
import { BuilderComponent } from '@/utils/cms';
import { ProductCard, ProductCardData } from '@/utils/productCard';

export const GiftBoxShipmentsDetailsRegistration: BuilderComponent = {
  name: 'GiftBox Shipments Details',
  inputs: [
    {
      name: 'boxes',
      type: 'array',
      defaultValue: [
        {
          price: 0,
          sku: '',
        },
      ],
      subFields: [
        {
          name: 'price',
          type: 'number',
        },
        {
          name: 'sku',
          type: 'string',
        },
      ],
    },
  ],
};
</script>

<script setup lang="ts">
const { boxes } = defineProps<{
  boxes: {
    price: number;
    sku: string;
  }[];
}>();

const variants = ref<ProductCardData[]>([]);
const variantsBySku = computed(() =>
  variants.value.reduce<Record<string, ProductCardData>>(
    (acc, variant) => ({
      ...acc,
      [variant.sku]: variant,
    }),
    {},
  ),
);

onMounted(async () => {
  const algoliaClient = initAlgolia();
  const productsIndex = getIndex(algoliaClient, 'Products');
  const products = await browseProducts(productsIndex, {
    facetFilters: [boxes.map((box) => `sku:${box.sku}`)],
  });

  variants.value = products.map(ProductCard.fromAlgolia) || [];
});
</script>

<template>
  <ul
    class="relative grid grid-cols-12 lg:grid-cols-[repeat(auto-fit,minmax(20rem,1fr))] gap-y-8 lg:gap-x-8"
  >
    <template v-for="(box, index) in boxes" :key="box.sku">
      <GiftBoxShipment
        v-if="variantsBySku[box.sku]"
        class="col-span-12 lg:col-auto"
        :index
        :price="from(box.price)"
        :variant="variantsBySku[box.sku]"
      />
    </template>
  </ul>
</template>
