<script lang="ts" setup>
import { computed } from 'vue';

import { NutritionFacts as NutritionFactsType } from '@/api/productNutritionFacts';
import { TagReference } from '@/api/productTagReferences';
import Header4 from '@/components/base/typography/Header4.vue';
import Header5 from '@/components/base/typography/Header5.vue';
import LargeBodyText from '@/components/base/typography/LargeBodyText.vue';
import GalleryProductFacts from '@/components/pdp/GalleryProductFacts.vue';
import NutritionFacts from '@/components/pdp/ingredients/NutritionFacts.vue';
import { isPhysical as isPhysicalProduct, NutsProductVariant } from '@/utils/product';

const props = defineProps<{
  isMobile?: boolean;
  nutritionFacts?: NutritionFactsType;
  productName?: string;
  tagsReferences?: TagReference[];
  variant: NutsProductVariant;
}>();

const isPhysical = computed(() => isPhysicalProduct(props.variant));

const showReadMore = computed(
  () => props.variant.ingredients && props.variant.ingredients.length > 450,
);
</script>

<template>
  <div
    class="flex gap-3 py-6 rounded-lg md:px-6 px-11 bg-nuts-orange-50 flex-start justify-content"
    style="min-width: 800px; min-height: 490px"
  >
    <NutritionFacts
      v-if="nutritionFacts"
      class="p-2 py-4 bg-white border border-white border-solid rounded-lg shadow"
      data-test="nutrition-facts-section"
      :idPrefix="isMobile ? 'gallery-mobile' : 'gallery-desktop'"
      :nutritionFacts="nutritionFacts"
      :servingsPerContainer="variant.servingsPerContainer"
      style="max-width: 40%"
    />
    <div>
      <Header4>
        {{ productName }}
      </Header4>
      <div v-if="variant.ingredients || isPhysical" class="pt-4">
        <Header5>Ingredients:</Header5>
        <LargeBodyText class="mt-3 font-normal-overwrite">
          {{ showReadMore ? variant.ingredients?.substring(0, 172) + '...' : variant.ingredients }}
        </LargeBodyText>
        <LargeBodyText v-if="showReadMore" class="mt-4 font-normal-overwrite">
          (Read full ingredient list under the “Ingredients & Nutrition Facts” section on page)
        </LargeBodyText>
        <LargeBodyText v-if="isPhysical" class="mt-4 font-normal-overwrite">
          Packaged in the same facility as: Peanuts, Tree Nut, Wheat, Soy, Milk, Sesame, Fish,
          Shellfish, Egg, and Sulfites.
        </LargeBodyText>
        <LargeBodyText v-if="variant.countPerPound" class="mt-4 font-normal-overwrite">
          Approx. {{ variant.countPerPound }} pieces per pound.
        </LargeBodyText>
        <GalleryProductFacts
          :perServingNutritionFacts="nutritionFacts?.perServing"
          :tagsReferences="tagsReferences"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>
.font-normal-overwrite {
  @apply font-normal;
}
</style>
