<script setup lang="ts">
import { computed } from 'vue';

import { useFeatureFlags } from '@/composables/useFeatureFlags';

const VALID_UNIT = /(\d+(?:\.\d+)?)(lb|oz)/;

const props = defineProps<{ bagSize: string; unitName: string }>();

const type = computed(() => (props.bagSize.includes('Container') ? 'Container' : 'Bag'));
const unit = computed(() => props.unitName.match(VALID_UNIT));

const showUpdatedUnit = computed(
  () => props.unitName === 'Value Pack' || props.unitName === 'Bulk Pack',
);
const { flags } = useFeatureFlags();
</script>

<template>
  <div v-if="unit || showUpdatedUnit" class="bg-nuts-sky-950 rounded-[100%] p-2">
    <span class="flex flex-col items-center justify-center aspect-square">
      <span
        v-if="flags.testVariantNameDisplay"
        class="w-24 text-2xl text-center text-white font-sofia-pro"
      >
        {{ unitName }}
      </span>
      <template v-else-if="unit">
        <span>
          <span class="text-4xl font-bold text-white capitalize font-sofia-pro">
            {{ unit[1] }}
          </span>
          <span class="text-2xl text-white font-sofia-pro">
            {{ unit[2] }}
          </span>
        </span>
        <span class="w-24 text-lg font-semibold text-center text-white capitalize font-sofia-pro">
          {{ type }}
        </span>
      </template>
    </span>
  </div>
</template>
