<template>
  <div class="w-32 px-3 py-1 ml-2 text-white skew-background bg-amber-700">
    <p class="text-sm text-center oos-badge">Out of stock!</p>
  </div>
</template>

<style scoped>
p {
  @apply mb-0;
}

.skew-background {
  clip-path: polygon(6% 0, 100% 0%, 94% 100%, 0 99%);
}
</style>
