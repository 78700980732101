<script setup lang="ts">
import { money } from '@nuts/auto-delivery-sdk/dist/utils/format';
import { cents } from '@nuts/auto-delivery-sdk/dist/utils/money';
import { computed } from 'vue';

import AppliedDiscountsPopper from '@/components/base/AppliedDiscountsPopper.vue';
import InfoIcon from '@/components/base/assets/InfoIcon.vue';
import SmallBodyText from '@/components/base/typography/SmallBodyText.vue';
import type { CostSaving } from '@/composables/useProductDetail';
import { computeSavings } from '@/utils/money';
import { NutsProductVariant } from '@/utils/product';

const props = defineProps<{
  discounts: CostSaving[];
  fullWidth?: boolean;
  savings?: ReturnType<typeof computeSavings>;
  price?: NutsProductVariant['prices'][number];
}>();

const savingsMap = computed<Record<CostSaving['type'], string>>(() => ({
  'auto-delivery': 'Auto-Delivery',
  bulk: 'Bulk discount',
  wholesale: 'Bulk discount',
  cart: 'Cart discount',
  product: props.price?.discounted?.discount.description || '',
}));
</script>

<template>
  <SmallBodyText
    v-if="discounts.length"
    class="inline-flex items-center justify-center px-2 py-1 rounded-md sm:rounded-2xl"
    :class="[
      discounts[0].type === 'product' ? 'bg-nuts-red-200' : 'bg-nuts-sky-100',
      {
        'w-full': fullWidth,
      },
    ]"
    data-promo="1"
    data-promo-creative="PDP"
    data-promo-name="Discounts Tooltip"
    data-test="discounts-percentage"
  >
    <span v-if="savings && cents(savings.value) > 0">
      You save
      <strong class="inline-flex">
        {{ money(savings.value) }}
        <template v-if="savings.percent">({{ savings.percent }}%)</template>
      </strong>
      <span v-if="discounts.length === 1" class="ml-1">
        {{ savingsMap[discounts[0].type] }}
      </span>
    </span>
    <span v-else-if="discounts.length === 1 && discounts[0].label">
      {{ discounts[0].label }}
    </span>
    <span v-if="discounts.length > 1" :class="{ 'ml-1': savings }">
      <strong>{{ discounts.length }}</strong>
      <span class="ml-1">Discounts applied</span>
    </span>
    <AppliedDiscountsPopper v-if="discounts.length > 1" :discounts="discounts">
      <InfoIcon class="text-sky-300" alt="Applied Discounts Info Icon" :size="16" />
    </AppliedDiscountsPopper>
  </SmallBodyText>
</template>
