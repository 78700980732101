<script setup lang="ts">
import { TypedMoney } from '@commercetools/platform-sdk';
import { useAnnouncer } from '@vue-a11y/announcer';
import { useDocumentVisibility, useIntervalFn, whenever } from '@vueuse/core';
import dayjs from 'dayjs';
import { storeToRefs } from 'pinia';
import { computed, onMounted, ref, watch } from 'vue';

import { fromNutsJson, unwrapNutsJsonErrors } from '@/api';
import { getConfigEntry } from '@/api/config';
import {
  calculateShippingOffers,
  ShippingCalculatorRequest,
  ShippingOfferSet,
} from '@/api/shippingCalculator';
import SmallBodyText from '@/components/base/typography/SmallBodyText.vue';
import PostalCodeButton from '@/components/pdp/buy-block/PostalCodeButton.vue';
import PostalCodeInput from '@/components/pdp/buy-block/PostalCodeInput.vue';
import PredictedShipping from '@/components/pdp/buy-block/PredictedShipping.vue';
import { useCallback } from '@/composables/useCallback';
import { useLocalStorage } from '@/composables/useLocalStorage';
import { useInitialRequest } from '@/stores/initialRequest';
import { validationRulesForCountry } from '@/utils/address';
import { secondsToHoursAndMinutes } from '@/utils/dateTime';
import {
  buildPostalCodeShippingCalculatorRequest,
  PredictedShipping as PredictedShippingType,
} from '@/utils/product';
import { ProductCardData } from '@/utils/productCard';

const props = defineProps<{
  predictedShipping?: PredictedShippingType;
  quantity: number;
  hideTruckIcon?: boolean;
  selectedAddOns?: { addOn: ProductCardData; quantity: number }[];
  sku: string;
  totalPrice: TypedMoney;
  weight: number;
}>();

const { polite } = useAnnouncer();

const { geoIp } = storeToRefs(useInitialRequest());
const { postalCode: postalCodeCA } = validationRulesForCountry('CA');

const initialized = ref(false);
const currentDate = ref(dayjs().startOf('day'));
const offerSet = ref<ShippingOfferSet>();
const cityName = computed(() => offerSet.value?.address.city);
const postalCode = ref(geoIp.value.postalCode ?? '');
const showPostalCodeForm = ref(false);
const canadianDestination = computed(() => postalCodeCA.regex.test(postalCode.value));

const shippingOffer = computed(() =>
  (offerSet.value?.shippingOffers ?? []).find((offer) => !offer.hidden && offer.name !== 'TBD'),
);

const pickupCutoff = computed(() => dayjs(shippingOffer.value?.targetPickupCutoff));
const expiresAt = computed(() => pickupCutoff.value ?? dayjs());
const expiresInSeconds = ref<number>(expiresAt.value.diff(currentDate.value, 'seconds'));
const countDownText = computed(
  () => secondsToHoursAndMinutes(expiresInSeconds.value, expiresInSeconds.value < 60) || '',
);

const getArrivalDay = (dateString: string) => {
  const date = dayjs(dateString);
  const dateDiffInDays = date.diff(currentDate.value, 'days');
  if (dateDiffInDays === 0) return 'Today,';
  if (dateDiffInDays === 1) return 'Tomorrow,';
  return `${date.format('dddd')},`;
};

const getArrivalDate = (dateString: string) => {
  const date = dayjs(dateString);
  const dateNumber = date.date();
  const dateMonth = date.format('MMMM');
  return `${dateMonth} ${dateNumber}`;
};

const isDeliveryWithinThreshold = computed(() => {
  if (!shippingOffer.value) return false;
  const delivery = dayjs(shippingOffer.value.latestArrivalOn);
  const latestArrivalThreshold = Number(
    getConfigEntry('preCheckoutDeliveryEstimate').daysThreshold,
  );
  return delivery.diff(currentDate.value, 'days') < latestArrivalThreshold;
});

const fetchShippingOffers = async (request: ShippingCalculatorRequest) => {
  try {
    const { offerSets } = await fromNutsJson(calculateShippingOffers(request, { timeout: 2000 }));
    return offerSets;
  } catch (err: any) {
    const errors = unwrapNutsJsonErrors(err);
    const message = errors?.[0]?.message;
    throw message ? new Error(message) : err;
  }
};

const { pause, resume } = useIntervalFn(
  () => {
    expiresInSeconds.value = expiresAt.value.diff(dayjs(), 'seconds');
  },
  1000,
  { immediate: false },
);

const getCachedKey = (postCode: string) => {
  let key = `${props.sku}:${props.quantity}:${postCode}`;

  if (props.selectedAddOns?.length) {
    const addOns = [...props.selectedAddOns]
      .sort((a, b) => (a.addOn.sku > b.addOn.sku ? 1 : -1))
      .map(({ addOn, quantity }) => `${addOn.sku}:${quantity}`);
    key = `${props.sku}:${props.quantity}:${addOns.join(':')}:${postCode}`;
  }

  return key;
};

const getShippingOffer = useCallback(async (postCode: string) => {
  if (!postCode) return;
  const localStorage = useLocalStorage('pdp-delivery-estimate');
  if (postalCode.value !== postCode) {
    localStorage.set<string>('postal-code', postCode);
  }
  postalCode.value = postCode;
  polite(`ZIP code updated to ${postalCode.value}`);
  pause();
  if (canadianDestination.value) return;

  const key = getCachedKey(postCode);

  const cachedData = localStorage.get<Record<string, ShippingOfferSet>>('cache') ?? {};
  const cachedOffer = cachedData[key];
  if (cachedOffer && dayjs(cachedOffer.shippingOffers[0].targetPickupCutoff).isAfter(dayjs())) {
    offerSet.value = cachedOffer;
    resume();
  } else {
    delete cachedData[key];
    const items = [{ quantity: props.quantity, sku: props.sku, weight: props.weight }];

    if (props.selectedAddOns?.length) {
      props.selectedAddOns.forEach(({ addOn, quantity }) => {
        items.push({
          quantity,
          sku: addOn.sku,
          weight: addOn.weight !== undefined ? Number(addOn.weight) : 0,
        });
      });
    }
    const request = buildPostalCodeShippingCalculatorRequest(
      items,
      {
        country: geoIp.value.country ?? undefined,
        postalCode: postCode,
      },
      props.totalPrice,
      {
        regionalCarriersAllowed: true,
      },
    );
    const [defaultOffer] = await fetchShippingOffers(request);
    if (defaultOffer.shippingOffers.length) {
      cachedData[key] = defaultOffer;
    }
    offerSet.value = defaultOffer;
    localStorage.set<typeof cachedData>('cache', cachedData);
    resume();
  }
});

const shippingOfferTimeout = computed(() => getShippingOffer.error?.message.includes('timeout'));
const zipCodeIsInvalid = computed(
  () => !!getShippingOffer?.error?.message.includes('Invalid postal code'),
);

whenever(zipCodeIsInvalid, () => {
  const localStorage = useLocalStorage('pdp-delivery-estimate');
  localStorage.set('postal-code', '');
});

watch(expiresAt, (value) => {
  expiresInSeconds.value = value.diff(dayjs(), 'seconds');
});

const handleUpdate = async (postCode: string) => {
  await getShippingOffer.execute(postCode);
};

onMounted(() => {
  watch(
    () => [props.sku, props.quantity, props.selectedAddOns],
    async () => {
      const localStorage = useLocalStorage('pdp-delivery-estimate');
      const savedPostalCode = localStorage.get<string>('postal-code');
      await getShippingOffer.execute(savedPostalCode || postalCode.value);
      initialized.value = true;
    },
    { immediate: true, deep: true },
  );

  const visibility = useDocumentVisibility();
  watch(visibility, (current) => {
    if (current === 'visible' && !getShippingOffer.error) {
      resume();
    } else if (current === 'hidden') {
      pause();
    }
  });
});

watch(expiresInSeconds, (value) => {
  if (value < 0 && !getShippingOffer.isPending) {
    getShippingOffer.execute(postalCode.value);
  }
});
</script>

<template>
  <div :class="{ invisible: !initialized }">
    <div
      class="flex flex-row text-sm text-black"
      :class="{ 'opacity-50': getShippingOffer.isPending }"
      data-test="delivery-estimate"
    >
      <img
        v-if="!hideTruckIcon"
        aria-hidden="true"
        alt="shipping"
        class="self-center flex-shrink-0 object-contain w-10 h-10 mr-3"
        src="@/assets/pdp/truck.svg"
      />
      <div v-if="!postalCode || shippingOfferTimeout" class="flex flex-col gap-y-1">
        <PredictedShipping
          v-if="predictedShipping"
          countdownColor="text-nuts-lime-900"
          :predictedShipping="predictedShipping"
        />
        <PostalCodeButton v-model="showPostalCodeForm" aria-controls="postal-code-form">
          Enter zip code for arrival date
        </PostalCodeButton>
      </div>
      <div v-else-if="canadianDestination && predictedShipping" class="flex flex-col gap-y-1">
        <PredictedShipping
          countdownColor="text-nuts-lime-900"
          :predictedShipping="predictedShipping"
        />
        <PostalCodeButton v-model="showPostalCodeForm" aria-controls="postal-code-form">
          {{ postalCode }} - Canada
        </PostalCodeButton>
      </div>
      <div v-else-if="getShippingOffer.error" class="flex flex-col gap-y-1">
        <SmallBodyText class="text-nuts-red-800">
          <template v-if="zipCodeIsInvalid">
            Hmm. This product can't be sent to your postal code. Please update your postal code.
          </template>
          <template v-else>
            {{ getShippingOffer.error.message }}
          </template>
        </SmallBodyText>
        <PostalCodeButton v-model="showPostalCodeForm" aria-controls="postal-code-form">{{
          postalCode
        }}</PostalCodeButton>
      </div>
      <div v-else-if="shippingOffer" class="flex flex-col gap-y-1">
        <SmallBodyText class="flex" data-test="estimated-delivery-date">
          <template v-if="isDeliveryWithinThreshold">
            Arrives by
            <SmallBodyText class="ml-1 px-1.5 bg-nuts-lime-100">
              <strong> {{ getArrivalDay(shippingOffer.latestArrivalOn) }} </strong>
              {{ getArrivalDate(shippingOffer.latestArrivalOn) }}
            </SmallBodyText>
          </template>
          <PredictedShipping
            v-else-if="predictedShipping"
            countdownColor="text-nuts-lime-900"
            :predictedShipping="predictedShipping"
          />
        </SmallBodyText>
        <div class="flex items-center gap-x-2" data-test="order-within-hours">
          <SmallBodyText v-if="isDeliveryWithinThreshold">
            Order within
            <strong class="inline-flex text-nuts-lime-900">{{ countDownText }}.</strong>
          </SmallBodyText>
          <PostalCodeButton v-model="showPostalCodeForm" aria-controls="postal-code-form">
            <template v-if="cityName"> {{ cityName.toLocaleLowerCase() }} - </template>
            {{ postalCode }}
          </PostalCodeButton>
        </div>
      </div>
    </div>
    <div v-show="showPostalCodeForm" class="mt-3">
      <PostalCodeInput
        :disabled="getShippingOffer.isPending"
        :error="getShippingOffer.error"
        id="postal-code-form"
        @update="handleUpdate"
      />
    </div>
  </div>
</template>
