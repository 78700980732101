<script setup lang="ts">
import { useVModel } from '@vueuse/core';

import ChevronIcon from '@/components/base/assets/ChevronIcon.vue';
import SmallBodyText from '@/components/base/typography/SmallBodyText.vue';
import UnstyledButton from '@/components/base/UnstyledButton.vue';

const props = defineProps<{ modelValue: boolean }>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void;
}>();

const isShown = useVModel(props, 'modelValue', emit);
</script>

<template>
  <UnstyledButton :aria-expanded="isShown" class="flex items-center" @click="isShown = !isShown">
    <SmallBodyText class="underline capitalize text-nuts-cyan-800" data-test="estimated-zipcode">
      <slot />
    </SmallBodyText>
    <ChevronIcon
      class="object-contain text-nuts-cyan-800 shrink-0"
      :direction="isShown ? 'up' : 'down'"
      :size="[16, 20]"
    />
    <span class="sr-only">postal code form</span>
  </UnstyledButton>
</template>
