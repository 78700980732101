import { Channel, Price, ProductDiscountValue, TypedMoney } from '@commercetools/platform-sdk';

import { baseMoneyFragment } from '@/graphql/fragments/baseMoney';

const priceFields = `
id
channel {
  id
  key
}
discounted {
  discount {
    description(locale: $locale)
    id
    name(locale: $locale)
    validUntil
    value {
      type
      ... on AbsoluteDiscountValue {
        money {
            ...baseMoneyFragment
        }
      }
      ... on RelativeDiscountValue {
        permyriad
      }
    }
  }
  value { ...baseMoneyFragment }
}
tiers {
  minimumQuantity
  value { ...baseMoneyFragment }
}
value { ...baseMoneyFragment }
`;

export const productPriceSearchFragment = `
fragment productPriceSearchFragment on ProductPriceSearch {
  ${priceFields}
}

${baseMoneyFragment}
`;

export const productPriceFragment = `
fragment productPriceFragment on ProductPrice {
  ${priceFields}
}

${baseMoneyFragment}
`;

export interface PriceFragment {
  id: Price['id'];
  channel?: Pick<Channel, 'id' | 'key'>;
  discounted?: {
    discount: {
      description?: string | null;
      id: string;
      name?: string | null;
      validUntil?: string | null;
      value: ProductDiscountValue;
    };
    value: TypedMoney;
  };
  tiers?: {
    minimumQuantity: number;
    value: TypedMoney;
  }[];
  value: TypedMoney;
}
