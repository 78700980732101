<script lang="ts">
import { from, multiply } from '@nuts/auto-delivery-sdk/dist/utils/money';
import { computedAsync, useElementVisibility, useMounted } from '@vueuse/core';
import { useHead } from '@vueuse/head';
import { computed, onMounted, onServerPrefetch, ref, useTemplateRef } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import { getPredictedShippingCutoff } from '@/api/predictedShipping';
import AddToCartOrCustomize from '@/components/base/add-to-cart/AddToCartOrCustomize.vue';
import Breadcrumb from '@/components/base/layout/Breadcrumb.vue';
import Header5 from '@/components/base/typography/Header5.vue';
import Header6 from '@/components/base/typography/Header6.vue';
import DeliveryEstimate from '@/components/pdp/buy-block/DeliveryEstimate.vue';
import PricePresentation from '@/components/pdp/buy-block/PricePresentation.vue';
import VariantOption from '@/components/pdp/buy-block/VariantOption.vue';
import GalleryWrapper from '@/components/pdp/GalleryWrapper.vue';
import PDPTitle from '@/components/pdp/PDPTitle.vue';
import { useCallback } from '@/composables/useCallback';
import { useCart } from '@/composables/useCart';
import money from '@/filters/money';
import { useProductStore } from '@/stores/productDetail';
import { sendProductAddedEvent } from '@/utils/analytics/productAddedEvent';
import { getCartId } from '@/utils/cart';
import { BuilderComponent } from '@/utils/cms';
import { computeSavings } from '@/utils/money';
import { getPrice, getUnitName, priceForQuantity, PurchaseOptions } from '@/utils/product';

export const GiftBoxBuyBlockRegistration: BuilderComponent = {
  name: 'GiftBox Buy Block',
  canHaveChildren: true,
  defaultChildren: [
    {
      '@type': '@builder.io/sdk:Element',
      component: { name: 'Box', options: { text: 'Extra content here' } },
    },
  ],
  inputs: [{ name: 'productKey', type: 'string' }],
};
</script>

<script setup lang="ts">
const { productKey } = defineProps<{
  productKey: string;
}>();

const router = useRouter();
const store = useStore();

const { cart, addToCart } = useCart(store);
const productStore = useProductStore(productKey);
const addToCartButton = useTemplateRef<HTMLElement>('addToCartButton');
const isAddToCartVisible = useElementVisibility(addToCartButton);
const footer = ref<HTMLElement>();
const isFooterVisible = useElementVisibility(footer);

const isMounted = useMounted();

const product = computed(() => (productStore.rawProduct ? productStore.product : undefined));
const breadcrumbs = computed(() => [
  { name: 'Home', url: '/' },
  ...(product.value?.ancestors || []),
]);
const selectedVariant = computed(() => product.value?.variants[0]);
const selections: PurchaseOptions = {
  markedAsGift: false,
  quantity: 1,
  sku: selectedVariant.value?.sku || '',
  variation: selectedVariant.value?.id || 0,
};
const tagNames = computed(() => {
  if (product.value?.productDiscountApplied) return ['badge-onsale'];
  return productStore.tagsReferences?.map((tag) => tag.name) ?? [];
});
const titleImage = computed(
  () => selectedVariant.value?.titleImage || product.value?.titleImage || undefined,
);
const images = computed(() => selectedVariant.value?.images || product.value?.images || []);
const predictedShipping = computedAsync(async () => {
  const shippingCutoff = await getPredictedShippingCutoff();
  return shippingCutoff;
});

const totalPrice = computed(() => {
  if (!selectedVariant.value) return from(0);
  return multiply(from(priceForQuantity(selectedVariant.value, selections)), selections.quantity);
});

const handleAddToCart = useCallback(async () => {
  if (!product.value || !selectedVariant.value) return;

  const finalPrice = getPrice(selectedVariant.value);
  const discount = finalPrice.discounted
    ? computeSavings(finalPrice.value, finalPrice.discounted.value).value
    : undefined;

  sendProductAddedEvent({
    // TODO do we want `listMetadata` related fields here?
    cartId: getCartId(cart.value),
    cost: selectedVariant.value.pieceCost,
    coupon: finalPrice.discounted?.discount.description ?? undefined,
    discount,
    name: product.value.name,
    pageSection: 'Buy Block',
    price: priceForQuantity(selectedVariant.value, selections),
    priceBeforeDiscount: selectedVariant.value.prices.find((p) => !p.channel)!.value,
    productId: product.value.key,
    quantity: selections.quantity,
    reportingCategory: selectedVariant.value.reportingCategory,
    sku: selectedVariant.value.sku,
    variant: getUnitName(selectedVariant.value.variantName) ?? undefined,
    weight: selectedVariant.value.weight,
  });

  await addToCart(
    {
      cart_sku: {
        quantity: selections.quantity,
        sku_external_id: selectedVariant.value.sku,
      },
    },
    {
      router,
      postAddToCartCallback: () => null,
    },
  );
});

onMounted(() => {
  productStore.getProduct.execute();
  productStore.getTagReferences.execute();

  footer.value = document.querySelector('footer') ?? undefined;
});

useHead({
  bodyAttrs: {
    class: computed(() =>
      isAddToCartVisible.value ? [] : ['buy-block-offset-bottom-anchored-elements'],
    ),
  },
});

onServerPrefetch(async () => {
  await Promise.all([productStore.getProduct.execute(), productStore.getTagReferences.execute()]);
});
</script>

<template>
  <div v-if="product && selectedVariant">
    <Breadcrumb :ancestors="breadcrumbs" />
    <div class="flex flex-col gap-4 mt-8 lg:flex-row lg:gap-8">
      <div class="lg:hidden">
        <PDPTitle>
          {{ product.name }}
          <template v-if="product.oneLinerDescription" #description>
            {{ product.oneLinerDescription }}
          </template>
        </PDPTitle>
        <PricePresentation
          v-if="selectedVariant"
          class="mt-4"
          :product
          :selectedVariant
          :selections
        />
      </div>

      <div class="relative flex-1 -mx-4 lg:h-[448px] xl:h-[512px]">
        <GalleryWrapper
          v-if="selectedVariant"
          headerTag="h2"
          :images
          isGift
          :productName="product.name"
          :tagNames
          :titleImage
          :variant="selectedVariant"
        />
      </div>

      <div class="flex-1 border rounded-lg shrink-0 lg:max-w-lg xl:max-w-xl border-neutral-300">
        <div class="hidden lg:block lg:visible">
          <PDPTitle>
            {{ product.name }}
            <template v-if="product.oneLinerDescription" #description>
              {{ product.oneLinerDescription }}
            </template>
          </PDPTitle>
          <PricePresentation class="mt-4" :product :selectedVariant :selections />
        </div>

        <fieldset class="lg:mt-6">
          <legend class="sr-only">Options</legend>
          <VariantOption
            v-model="selections.sku"
            class="w-full lg:max-w-xs max-w-60"
            isSelected
            :selections
            :variant="selectedVariant"
          />
        </fieldset>

        <DeliveryEstimate
          class="mt-4 lg:mt-6"
          :predictedShipping
          :quantity="selections.quantity"
          :sku="selectedVariant.sku"
          :totalPrice
          :weight="selectedVariant.weight"
        />

        <AddToCartOrCustomize
          class="h-12 mt-4"
          :disabled="selectedVariant.backordered"
          fullWidth
          :isLoading="handleAddToCart.isPending"
          ref="addToCartButton"
          @add-to-cart="handleAddToCart.execute"
        >
          <template #add-to-cart-text>
            <Header6 class="semibold" headerTag="p">Add to Cart</Header6>
          </template>
        </AddToCartOrCustomize>

        <div
          v-show="isMounted && !isAddToCartVisible && !isFooterVisible"
          class="fixed bottom-0 left-0 right-0 z-10 flex items-center justify-between px-8 py-4 mt-0 bg-white large-shadow"
        >
          <Header5 class="hidden md:visible md:block">3 Month Subscription Bundle</Header5>
          <div class="w-full md:w-[286px]">
            <AddToCartOrCustomize
              class="h-12"
              :disabled="selectedVariant.backordered"
              fullWidth
              :isLoading="handleAddToCart.isPending"
              @add-to-cart="handleAddToCart.execute"
            >
              <template #add-to-cart-text>
                <Header6 class="semibold" headerTag="p">
                  Add to Cart - {{ money(totalPrice) }}
                </Header6>
              </template>
            </AddToCartOrCustomize>
          </div>
        </div>

        <slot />
      </div>
    </div>
  </div>
</template>

<style scoped>
.large-shadow {
  box-shadow: -8px 25px 50px -12px rgba(0, 0, 0, 0.25);
}
</style>

<style>
.buy-block-offset-bottom-anchored-elements {
  iframe#attentive_creative,
  #new-user-free-ship-banner {
    @apply mb-16;
  }

  #gladlyChat_container {
    bottom: 4rem !important;
  }
}
</style>
