<script setup lang="ts">
import { computed } from 'vue';

import RouteLink from '@/components/base/RouteLink.vue';
import { DataPromo } from '@/utils/analytics';

const props = defineProps<{
  ancestors: { name: string; url: string }[];
  dataPromo?: DataPromo;
  scrollPaddingClasses?: string;
}>();

const breadcrumbs = computed(() =>
  !props.ancestors.length ? [{ name: 'Home', url: '/' }] : props.ancestors,
);
const leftCaret = nutshell['img/left_caret.svg'];

const classString = computed(() => props.scrollPaddingClasses?.split(' '));
</script>

<template>
  <nav
    aria-label="breadcrumbs"
    class="overflow-x-scroll scrollbar-width-none"
    id="breadcrumbs"
    data-test="breadcrumbs"
  >
    <ol class="flex items-start list-none gap-x-2 w-max" :class="classString">
      <li
        v-for="(breadcrumb, index) in breadcrumbs"
        :key="breadcrumb.name"
        class="flex flex-shrink-0 flex-nowraps"
      >
        <RouteLink
          :to="breadcrumb.url"
          class="inline-flex items-center"
          :data-test="breadcrumb.name === 'Home' ? 'breadcrumb-home-link' : undefined"
          :data-promo="dataPromo?.type"
          :data-promo-creative="dataPromo?.creative"
          :data-promo-name="dataPromo?.name"
        >
          <span
            class="px-3 py-1 text-xs font-bold rounded-xl whitespace-nowrap bg-neutral-100 text-nuts-cyan-800 sm:text-sm"
          >
            {{ breadcrumb.name }}
          </span>
        </RouteLink>
        <img
          v-if="index !== breadcrumbs.length - 1"
          alt=""
          aria-hidden="true"
          class="object-contain ml-2"
          :src="leftCaret"
        />
      </li>
      <li v-if="$slots.default" class="flex items-center flex-shrink-0 flex-nowraps">
        <img alt="" aria-hidden="true" class="object-contain mr-2" :src="leftCaret" />
        <slot />
      </li>
    </ol>
  </nav>
</template>

<style scoped>
.scrollbar-width-none {
  scrollbar-width: none;
}
</style>
