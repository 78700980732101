<script setup lang="ts">
import { computed } from 'vue';

import ReadMore from '@/components/pdp/ReadMore.vue';

const props = defineProps<{
  description?: string;
  healthTips?: string;
  hideReadMore?: boolean;
}>();

const content = computed(() => {
  let description = props.description ?? '';
  if (props.healthTips) {
    description += `<h3>Health Tips</h3>${props.healthTips}`;
  }
  return description;
});
</script>

<template>
  <ReadMore
    v-if="description"
    class="description"
    data-test="description-container"
    :hideReadMore="hideReadMore"
    :htmlContent="content"
  />
</template>

<style lang="scss" scoped>
.description {
  &:deep(h2),
  &:deep(h3) {
    // large body text
    @apply text-base font-semibold md:text-lg md:leading-6;
    @media (max-width: 768px) {
      line-height: 22px;
    }
  }
  :deep(p) {
    @apply text-sm md:text-base md:leading-6 mb-4 text-black;
    @media (max-width: 768px) {
      line-height: 22px;
    }
  }
  @include respond-max($tailwind-xs-max) {
    &:deep(> div) {
      & > *:not(a) {
        display: none;
      }
      &:first-child {
        display: block;
      }
    }
    &:deep(> a) {
      @apply text-sm md:text-base md:leading-6 mb-4;
      @media (max-width: 768px) {
        line-height: 22px;
      }
    }
    &:deep(> div:first-child) > p:first-child {
      display: block;
    }
  }
}
</style>
