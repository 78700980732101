<script setup lang="ts">
import { computed } from 'vue';

import CaretArrowButton from '@/components/base/CaretArrowButton.vue';

const { edgeBehavior = 'visible', slideCount } = defineProps<{
  edgeBehavior?: 'disabled' | 'hidden' | 'visible';
  slideCount: number;
}>();

const currentIndex = defineModel<number>({
  default: 0,
  set: (value) => {
    const index = value < 0 ? slideCount - 1 : value;
    return index % slideCount;
  },
});

const isLeftVisible = computed(() => edgeBehavior !== 'hidden' || currentIndex.value !== 0);
const isRightVisible = computed(
  () => edgeBehavior !== 'hidden' || currentIndex.value !== slideCount - 1,
);
</script>

<template>
  <Transition name="fade" :duration="150">
    <CaretArrowButton
      v-show="isLeftVisible"
      ariaLabel="Previous image"
      class="absolute -translate-y-1/2 left-4 bg-white/80 text-neutral-500 top-1/2"
      :class="$attrs.class"
      direction="left"
      :disabled="edgeBehavior === 'disabled' && currentIndex === 0"
      @click="isLeftVisible && currentIndex--"
    />
  </Transition>
  <Transition name="fade" :duration="150">
    <CaretArrowButton
      v-show="isRightVisible"
      ariaLabel="Next image"
      class="absolute -translate-y-1/2 right-4 bg-white/80 text-neutral-500 top-1/2"
      :class="$attrs.class"
      direction="right"
      :disabled="edgeBehavior === 'disabled' && currentIndex === slideCount - 1"
      @click="isRightVisible && currentIndex++"
    />
  </Transition>
</template>
