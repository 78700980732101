<script setup lang="ts">
import { computed } from 'vue';

import { Fact, NutritionFacts } from '@/api/productNutritionFacts';
import Header4 from '@/components/base/typography/Header4.vue';
import FactRow from '@/components/pdp/ingredients/FactRow.vue';

interface NutrientsInfo extends Fact {
  name: string;
  textWithValue: string;
}

interface Nutrients extends NutrientsInfo {
  subnutrients?: NutrientsInfo[];
}

const props = withDefaults(
  defineProps<{
    idPrefix?: string;
    nutritionFacts: NutritionFacts;
    servingsPerContainer?: number;
  }>(),
  {
    idPrefix: 'facts',
  },
);

const perServing = computed(() => props.nutritionFacts?.perServing);

const calories = computed(() => perServing.value?.calories);
const caloriesQuantity = computed(() => calories.value?.quantity || '');

const cholesterol = computed(() => perServing?.value?.cholesterol);
const protein = computed(() => perServing?.value?.protein);
const saturatedFat = computed(() => perServing?.value?.saturatedFat);
const sodium = computed(() => perServing?.value?.sodium);
const totalFat = computed(() => perServing.value?.totalFat);
const transFat = computed(() => perServing?.value?.transFat);

const addedSugars = computed(() => perServing?.value?.addedSugars);
const dietaryFiber = computed(() => perServing?.value?.dietaryFiber);
const totalCarbohydrate = computed(() => perServing?.value?.totalCarbohydrate);
const totalSugars = computed(() => perServing?.value?.totalSugars);

const calcium = computed(() => perServing?.value?.calcium);
const iron = computed(() => perServing?.value?.iron);
const potassium = computed(() => perServing?.value?.potassium);
const vitaminD = computed(() => perServing?.value?.vitaminD);

const nutrients = computed(() => {
  const extendedNutrients: Nutrients[] = [
    {
      name: 'Total Fat',
      textWithValue: `Total Fat ${totalFat.value?.quantity}${totalFat.value?.unit}`,
      ...perServing.value.totalFat,
      subnutrients: [
        {
          name: 'Saturated Fat',
          textWithValue: `Saturated Fat ${saturatedFat.value?.quantity}${saturatedFat.value?.unit}`,
          ...perServing.value.saturatedFat,
        },
        {
          name: 'Trans Fat',
          textWithValue: `Trans Fat ${transFat.value?.quantity}${transFat.value?.unit}`,
          ...perServing.value.transFat,
        },
      ],
    },
    {
      name: 'Cholesterol',
      textWithValue: `Cholesterol ${cholesterol.value?.quantity}${cholesterol.value?.unit}`,
      ...perServing.value.cholesterol,
    },
    {
      name: 'Sodium',
      textWithValue: `Sodium ${sodium.value?.quantity}${sodium.value?.unit}`,
      ...perServing.value.sodium,
    },
    {
      name: 'Total Carbohydrate',
      textWithValue: `Total Carbohydrate ${totalCarbohydrate.value?.quantity}${totalCarbohydrate.value?.unit}`,
      ...perServing.value.totalCarbohydrate,
      subnutrients: [
        {
          name: 'Dietary Fiber',
          textWithValue: `Dietary Fiber ${dietaryFiber.value?.quantity}${dietaryFiber.value?.unit}`,
          ...perServing.value.dietaryFiber,
        },
        {
          name: 'Total Sugars',
          textWithValue: `Total Sugars ${totalSugars.value?.quantity}${totalSugars.value?.unit}`,
          ...perServing.value.totalSugars,
        },
        {
          name: 'Added Sugars',
          textWithValue: `Includes ${addedSugars.value?.quantity}${addedSugars.value?.unit} Added Sugars`,
          ...perServing.value.addedSugars,
        },
      ],
    },
    {
      name: 'Protein',
      textWithValue: `Protein ${protein.value?.quantity}${protein.value?.unit}`,
      ...perServing.value.protein,
    },
  ];

  return extendedNutrients
    .filter((nutrient) => 'pdv' in nutrient || 'quantity' in nutrient)
    .map((nutrient) => {
      if ('subnutrients' in nutrient) {
        return {
          ...nutrient,
          subnutrients: nutrient.subnutrients?.filter(
            (subnutrient) => 'pdv' in subnutrient || 'quantity' in subnutrient,
          ),
        };
      }

      return nutrient;
    });
});

const vitamins = computed(() => {
  const extendedVitamins: NutrientsInfo[] = [
    {
      name: 'Vitamin D',
      textWithValue: vitaminD.value?.quantity
        ? `Vitamin D ${vitaminD.value?.quantity}${vitaminD.value?.unit}`
        : 'Vitamin D',
      ...perServing.value.vitaminD,
    },
    {
      name: 'Calcium',
      textWithValue: calcium.value?.quantity
        ? `Calcium ${calcium.value?.quantity}${calcium.value?.unit}`
        : 'Calcium',
      ...perServing.value.calcium,
    },
    {
      name: 'Iron',
      textWithValue: iron.value?.quantity
        ? `Iron ${iron.value?.quantity}${iron.value?.unit}`
        : 'Iron',
      ...perServing.value.iron,
    },
    {
      name: 'Potassium',
      textWithValue: potassium.value?.quantity
        ? `Potassium ${potassium.value?.quantity}${potassium.value?.unit}`
        : 'Potassium',
      ...perServing.value.potassium,
    },
  ];

  return extendedVitamins.filter((vitamin) => 'pdv' in vitamin);
});

const servingSize = computed(() => {
  const fact = props.nutritionFacts.servingSize;
  if (!fact) return undefined;

  let description = fact.quantity + fact.unit;
  if (fact.description && fact.unit === 'g') {
    description += ` (${fact.description})`;
  }
  return description;
});

const hasPdvOrQuantity = computed(() =>
  Object.values(props.nutritionFacts.perServing).some(
    (nutritionFact) => nutritionFact.pdv || nutritionFact.quantity,
  ),
);
</script>

<template>
  <div v-if="hasPdvOrQuantity">
    <Header4 headingTag="h2">Nutrition Facts</Header4>
    <p v-if="servingSize" class="text-xs font-bold md:text-sm">
      Serving size {{ servingSize }}
      <template v-if="servingsPerContainer"> Approx {{ servingsPerContainer }} servings </template>
    </p>
    <table class="w-full table-fixed">
      <colgroup span="3" />
      <thead class="border-t-4 border-black border-solid">
        <tr>
          <th
            class="text-sm font-bold text-left"
            colspan="3"
            :id="`${idPrefix}-per-servings`"
            scope="colgroup"
          >
            Amount per serving
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th
            class="text-2xl font-bold leading-7 text-left"
            colspan="2"
            :id="`${idPrefix}-calories`"
            scope="colgroup"
          >
            Calories
          </th>
          <td
            class="text-2xl font-bold leading-7 text-right md:text-3xl"
            :headers="`${idPrefix}-calories ${idPrefix}-per-servings`"
          >
            {{ caloriesQuantity }}
          </td>
        </tr>
        <tr class="border-t-4 border-black border-solid">
          <th
            class="pt-1 text-center text-tiny md:text-right"
            colspan="3"
            :id="`${idPrefix}-daily-value`"
            scope="colgroup"
          >
            % Daily Value
          </th>
        </tr>
      </tbody>
      <tbody v-for="nutrient in nutrients" :key="nutrient.name">
        <FactRow
          v-bind="nutrient"
          class="border-b border-black border-solid"
          :id-prefix="idPrefix"
          mode="nutrient"
        />
        <FactRow
          v-for="subnutrient in nutrient.subnutrients"
          v-bind="subnutrient"
          class="border-b border-black border-solid"
          :id-prefix="idPrefix"
          :key="subnutrient.name"
          mode="subnutrient"
        />
      </tbody>
      <tbody class="border-t-4 border-black border-solid">
        <FactRow
          v-for="vitamin in vitamins"
          v-bind="vitamin"
          class="border-b border-black border-solid"
          :id-prefix="idPrefix"
          :key="vitamin.name"
          mode="vitamin"
        />
      </tbody>
    </table>
    <p class="pt-1 leading-3 text-tiny">
      The % Daily Value (DV) tells you how much a nutrient in a serving of food contributes to a
      daily diet. 2,000 calories a day is used for general nutrition advice
    </p>
  </div>
</template>

<style scoped>
p {
  @apply m-0;
}
</style>
