<script setup lang="ts">
import { computed, nextTick, onMounted, reactive, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

interface Props {
  active?: boolean;
  fontClass?: string;
  hideReadMore?: boolean;
  htmlContent: string;
  lineClamp?: number;
}

const props = withDefaults(defineProps<Props>(), {
  fontClass: 'text-sm md:text-base font-proxima-nova',
  lineClamp: 3,
});

const route = useRoute();
const readMoreWrapper = ref();
const linesCount = ref();

const content = computed(() => props.htmlContent.replace(/(\r\n|\n|\r)/gm, ''));
const showReadMore = computed(() => !props.hideReadMore && linesCount.value > 10);

const readMore = reactive({
  active: props.active,
  trigger: 'automatic' as 'click' | 'automatic',
});

const handleClick = () => {
  readMore.trigger = 'click';
  readMore.active = !readMore.active;
};

const calculateLines = () => {
  if (readMoreWrapper.value) {
    const lineHeight = parseFloat(window.getComputedStyle(readMoreWrapper.value).lineHeight);
    const readMoreWrapperHeight = readMoreWrapper.value.clientHeight;
    linesCount.value = Math.ceil(readMoreWrapperHeight / lineHeight);
  }
};

watch(
  () => readMore.active,
  (_, oldValue) => {
    setTimeout(() => {
      if (readMore.trigger === 'automatic') return;
      if (oldValue && readMoreWrapper.value) {
        readMoreWrapper.value.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }, 100);
  },
);

watch(route, () => {
  readMore.trigger = 'automatic';
  readMore.active = false;
  linesCount.value = 5;
});

watch(content, async () => {
  await nextTick();
  calculateLines();
});

onMounted(calculateLines);
</script>

<template>
  <div ref="readMoreWrapper">
    <span
      :class="[
        { 'mb-2': showReadMore },
        fontClass,
        { 'render-lines lg:h-full h-18': !readMore.active && showReadMore },
      ]"
      v-html="content"
    />
    <button
      v-if="showReadMore"
      class="p-0 mt-1 text-sm text-black underline bg-transparent border-0 appearance-none cursor-pointer sm:mt-0 font-proxima-nova md:text-base"
      type="button"
      @click.prevent="handleClick"
    >
      <slot :isReadMoreActive="readMore.active">
        Read {{ readMore.active ? 'less' : 'more' }}
      </slot>
    </button>
  </div>
</template>

<style lang="scss" scoped>
span.render-lines {
  position: relative;
  &::after {
    content: ' ';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 24px;
    background: rgb(255, 255, 255);
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 1) 4%,
      rgba(255, 255, 255, 0) 100%
    );
  }
}
.render-lines {
  // avoid using tailwind line-clamp plugin here because it is limited to 6 lines max
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: v-bind(lineClamp);
  -webkit-box-orient: vertical;
  height: 4.125rem;
  @media (min-width: 640px) {
    height: 4.5rem;
  }
}
</style>
