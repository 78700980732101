<script setup lang="ts">
import { useElementBounding, useScroll, useWindowSize, watchPausable } from '@vueuse/core';
import { useHead } from '@vueuse/head';
import type { Component } from 'vue';
import { computed, nextTick, onMounted, ref, watch } from 'vue';

import CaretArrowButton from '@/components/base/CaretArrowButton.vue';
import Thumbnails from '@/components/base/image-gallery/Thumbnails.vue';
import Header3 from '@/components/base/typography/Header3.vue';
import { TypographyTags } from '@/utils/accessibility';
import { DataPromo } from '@/utils/analytics';

export type CarouselItem = {
  miniature: string;
} & (
  | {
      alt?: string;
      /** image src url */
      content: string;
      type: 'image';
    }
  | {
      content: Component;
      type: 'html';
    }
);

export interface GalleryDataPromo {
  click: DataPromo;
  listImages: DataPromo;
  mainImage: DataPromo;
}

const { headerTag = 'h3', ...props } = defineProps<{
  carouselSlides: CarouselItem[];
  dataPromos?: GalleryDataPromo;
  headerTag?: TypographyTags;
  title?: string;
}>();

const hasInteracted = ref<boolean>(false);
const imageContent = ref<HTMLElement>();
const galleryContent = ref<HTMLElement>();
const htmlContent = ref<HTMLElement[]>();
const leftArrow = ref<HTMLElement>();
const rightArrow = ref<HTMLElement>();
const scaleGalleryHtml = ref<number[]>([]);
const currentIndex = ref(0);

const { width: windowWidth } = useWindowSize();

const showScroll = computed(() => props.carouselSlides.length > 1);

const imageContentMobile = ref<HTMLElement>();
const { x: scrollMobileX } = useScroll(imageContentMobile, {
  behavior: 'smooth',
});

const activeImageMobile = computed(() => {
  if (props.carouselSlides.length < 1) return undefined;
  return useElementBounding(imageContentMobile.value?.children[currentIndex.value] as HTMLElement);
});

const widthActiveImageMobile = computed(() => activeImageMobile.value?.width.value ?? 0);
const { width: widhtMobile } = useWindowSize();

const calculateScroll = (index: number): number => {
  const margin = (widhtMobile.value - widthActiveImageMobile.value) / 2;
  return (widthActiveImageMobile.value + margin) * index;
};

const calculateScaleForHtmlInsideGallery = () => {
  scaleGalleryHtml.value = [];
  const html = htmlContent.value ?? [];
  const galleryWidth = galleryContent.value?.offsetWidth ?? 1;
  const galleryHeight = imageContent.value?.offsetHeight ?? 1;
  const galleryMobileHeight = imageContentMobile.value?.offsetHeight ?? 1;
  const htmlIndexes = props.carouselSlides
    .map((slide, index) => (slide.type === 'html' ? index : undefined))
    .filter((index) => !!index) as number[];

  html.forEach((htmlElement) => {
    const contentWidth: number | undefined = htmlElement.children?.[0]?.offsetWidth;
    const contentHeight: number | undefined = htmlElement.children?.[0]?.offsetHeight;
    if (!contentWidth || !contentHeight) return;

    const finalGalleryHeight = galleryHeight > 0 ? galleryHeight : galleryMobileHeight;
    const scaleWidth = contentWidth < galleryWidth ? 1 : galleryWidth / contentWidth;
    const scaleHeight = contentHeight < finalGalleryHeight ? 1 : finalGalleryHeight / contentHeight;
    const indexGlobal = htmlIndexes.shift() ?? 0;
    scaleGalleryHtml.value[indexGlobal] = scaleWidth < scaleHeight ? scaleWidth : scaleHeight;
  });
};

const goTo = (index: number, scrollTo = false) => {
  currentIndex.value = index;
  hasInteracted.value = true;
  if (scrollTo) scrollMobileX.value = calculateScroll(index);
  calculateScaleForHtmlInsideGallery();
};

onMounted(() => {
  calculateScaleForHtmlInsideGallery();
});

watch(windowWidth, () => {
  calculateScaleForHtmlInsideGallery();
});

const { pause, resume } = watchPausable(
  () => scrollMobileX.value,
  (newScroll, oldScroll) => {
    const scroll = calculateScroll(1);
    const lastIndex = Math.round(oldScroll / scroll);
    const newIndex = Math.round(newScroll / scroll);
    const goToIndex =
      newIndex > props.carouselSlides.length - 1 ? props.carouselSlides.length - 1 : newIndex;
    if (lastIndex !== goToIndex) {
      goTo(goToIndex);
    }
  },
);

const goToNoScrollWatcher = (index: number) => {
  pause();
  goTo(index, true);
  setTimeout(() => {
    resume();
  }, 500);
};

watch(
  () => props.carouselSlides,
  async () => {
    await nextTick();
    if (currentIndex.value >= props.carouselSlides.length - 1) {
      goToNoScrollWatcher(props.carouselSlides.length - 1);
    } else {
      goToNoScrollWatcher(currentIndex.value);
      calculateScaleForHtmlInsideGallery();
    }
  },
);

watch(
  () => props.title,
  () => goTo(0, true),
);

const next = () => {
  goTo((currentIndex.value + 1) % props.carouselSlides.length);
};

const prev = () => {
  goTo((currentIndex.value - 1 + props.carouselSlides.length) % props.carouselSlides.length);
};

useHead(
  computed(() => ({
    link: [
      {
        rel: 'preload',
        as: 'image',
        fetchpriority: 'high',
        href: props.carouselSlides.find((i) => i.type === 'image')?.content,
      },
    ],
  })),
);
</script>

<template>
  <section
    v-if="carouselSlides.length > 0"
    class="flex flex-col items-center justify-center w-full h-full min-h-[240px]"
    data-test="gallery-images"
    aria-labelledby="carouselheading"
    ref="galleryContent"
  >
    <Header3 class="sr-only" id="carouselheading" :headerTag>Carousel Images</Header3>
    <div class="relative w-full h-full px-4 overflow-hidden sm:hidden sm:px-0">
      <ul
        class="relative flex w-full h-full gap-6 overflow-x-auto snap-x snap-mandatory no-scrollbar"
        ref="imageContentMobile"
      >
        <li
          v-for="(slide, index) in carouselSlides"
          :key="index"
          :aria-hidden="index !== currentIndex"
          class="relative flex items-center justify-center object-contain w-full h-full snap-always snap-center shrink-0"
          :data-slide="`${index}`"
          :tabindex="index === currentIndex ? '0' : '-1'"
        >
          <template v-if="slide.type === 'image'">
            <img
              :alt="slide.alt"
              class="object-contain max-w-full max-h-full"
              :data-promo="dataPromos?.mainImage.type"
              :data-promo-creative="dataPromos?.mainImage.creative"
              :data-promo-name="dataPromos?.mainImage.name"
              :fetchpriority="index === 0 ? 'high' : 'low'"
              :src="slide.content"
            />
          </template>
          <template v-else>
            <div
              class="flex items-center justify-center max-w-full max-h-full transition-opacity snap-always snap-center shrink-0"
              ref="htmlContent"
            >
              <slot :isMobile="true" :scale="scaleGalleryHtml[index]" :slide="slide" />
            </div>
          </template>
          <slot name="badge" :index="index" />
        </li>
      </ul>
    </div>

    <ul
      class="relative hidden w-full h-full overflow-hidden sm:flex sm:visible carousel rounded-xl"
      ref="imageContent"
    >
      <template v-for="(slide, index) in carouselSlides" :key="index">
        <li
          :aria-hidden="index !== currentIndex"
          class="absolute top-0 left-0 w-full h-full transition-transform duration-500 ease-in-out snap-x"
          :class="{
            '-z-10': index !== currentIndex,
            '-translate-x-full': index <= currentIndex - 1,
            'translate-x-0': index === currentIndex,
            'translate-x-full': index >= currentIndex + 1,
          }"
          :data-slide="`${index}`"
          :tabindex="index == currentIndex ? '0' : '-1'"
        >
          <template v-if="slide.type === 'image'">
            <div class="flex items-center justify-center w-full h-full snap-center">
              <img
                :alt="slide.alt"
                class="absolute object-contain max-w-full max-h-full transition-opacity duration-500"
                data-test="product-image"
                :data-promo="dataPromos?.mainImage.type"
                :data-promo-creative="dataPromos?.mainImage.creative"
                :data-promo-name="dataPromos?.mainImage.name"
                :fetchpriority="index === 0 ? 'high' : 'low'"
                :src="slide.content"
              />
            </div>
          </template>
          <template v-else>
            <div
              class="flex items-center justify-center w-full h-full transition-opacity duration-500 snap-center"
              :class="index === currentIndex ? 'opacity-100' : 'opacity-0'"
              ref="htmlContent"
            >
              <slot :isMobile="false" :scale="scaleGalleryHtml[index]" :slide="slide" />
            </div>
          </template>
          <slot name="badge" :index="index" />
        </li>
      </template>
    </ul>
    <div role="group" aria-label="Slide controls">
      <div
        class="absolute opacity-[88%] w-12 h-12 left-4 ws-md:left-0 bottom-[56%] sm:visible sm:block hidden"
        ref="leftArrow"
      >
        <CaretArrowButton
          :ariaLabel="`Previous Image - slide ${currentIndex + 1} of ${carouselSlides.length}`"
          class="text-neutral-500"
          :data-promo="dataPromos?.click.type"
          :data-promo-creative="dataPromos?.click.creative"
          :data-promo-name="dataPromos?.click.name"
          direction="left"
          :disabled="!hasInteracted || !showScroll"
          role="button"
          @click="prev"
          data-test="image-gallery-left-arrow"
        />
      </div>
      <div
        class="absolute opacity-[88%] w-12 h-12 right-4 ws-md:right-0 bottom-[56%] sm:visible sm:block hidden"
        ref="rightArrow"
      >
        <CaretArrowButton
          :ariaLabel="`Next Image - slide ${currentIndex + 1} of ${carouselSlides.length}`"
          class="text-neutral-500"
          :data-promo="dataPromos?.click.type"
          :data-promo-creative="dataPromos?.click.creative"
          :data-promo-name="dataPromos?.click.name"
          direction="right"
          :disabled="!showScroll"
          role="button"
          @click="next"
          data-test="image-gallery-right-arrow"
        />
      </div>
    </div>
    <div class="relative w-full">
      <div v-once class="absolute z-10 w-full pointer-events-none -top-4" data-tagg-slot />
      <Thumbnails
        :dataPromo="dataPromos?.listImages"
        :dataPromoClick="dataPromos?.click"
        :carouselSlides="carouselSlides"
        :currentIndex="currentIndex"
        @next="next"
        @previous="prev"
        @goTo="goToNoScrollWatcher($event)"
      />
    </div>
  </section>
</template>

<style scoped>
.no-scrollbar {
  -ms-overflow-style: none !important;
  scrollbar-width: 0 !important;
}
.no-scrollbar::-webkit-scrollbar {
  display: none;
}
</style>
