import {
  Attribute,
  Category as CTCategory,
  Image as CTImage,
  LocalizedString,
  Product as CTProduct,
  ProductVariant,
} from '@commercetools/platform-sdk';

import { PriceFragment, productPriceFragment } from '@/graphql/fragments/price';

const relatedProductsFragments = `
fragment relatedProductsFields on Product {
  id
  masterData {
    current {
      categories {
        ...categoryFields
      }
      masterVariant {
        id
        sku
        attributesRaw(includeNames: [
          "primaryCategory",
          "urlName",
          ]) {
          name
          value
        }
      }
      name(locale: "en")
    }
  }
}`;

export const variantFragments = `
fragment variantFields on ProductVariant {
  id
  sku
  prices {
    ...productPriceFragment
  }
  attributesRawRelatedProducts: attributesRaw(includeNames: [
    "upsellingProducts",
  ]) {
    name
    referencedResourceSet {
      ... on Product {
        ...relatedProductsFields
      }
    }
  }
  attributesRaw(includeNames: [
    "primaryCategory",
    "variantName",
    "active",
    "bagSize",
    "bulk",
    "countPerPound",
    "countryOfOrigin",
    "customPageTitle",
    "customProduct",
    "googleProductCategory",
    "merchandisingCategory",
    "reportingCategory",
    "grindSize",
    "hiddenFromSearch",
    "maximumPiecesPerOrder",
    "ingredients",
    "tinColor",
    "wholesale",
    "sample",
    "snackPack",
    "healthTips",
    "servingsPerContainer",
    "storageRequirements",
    "backordered",
    "backorderedUntil",
    "weight",
    "autoDeliveryEligible",
    "urlName",
    "customPageTitle",
    "pieceCost",
    "oneLinerDescription",
    "testDescription",
    "contentList",
    "offerGiftAddOns"
    ]) {
    name
    value
  }
  images {
    url
    label
  }
}
${relatedProductsFragments}
${productPriceFragment}`;

const detailedFragments = `
fragment productFields on Product {
  id
  key
  masterData {
    current {
      name(locale: $locale)
      description(locale: $locale)
      metaDescription(locale: $locale)
      categories {
        ...categoryFields
      }
      masterVariant {
        ...variantFields
      }
      variants {
        ...variantFields
      }
    }
  }
}

fragment categoryFields on Category {
  id
  version
  key
  name(locale: $locale)
  custom {
    customFieldsRaw(includeNames: ["urlName"]) {
      name
      value
    }
  }
  ancestors {
    id
    name(locale: $locale)
    custom {
      customFieldsRaw(includeNames: ["urlName"]) {
        name
        value
      }
    }
  }
}
${variantFragments}
`;

export interface RelatedVariant extends Pick<ProductVariant, 'id' | 'sku'> {
  attributesRaw: Attribute[];
}

interface Custom {
  customFieldsRaw: { name: string; value: string }[];
}

interface Ancestor extends Pick<CTCategory, 'id'> {
  custom?: Custom | null;
  name: string;
}

interface Category extends Pick<CTCategory, 'id' | 'key' | 'version'> {
  ancestors: Ancestor[];
  custom?: Custom | null;
  name: string;
}

export interface RelatedProduct extends Pick<CTProduct, 'id' | 'key'> {
  masterData: {
    current: {
      name: string;
      masterVariant: RelatedVariant;
      categories: Category[];
    };
  };
}

export type AttributeResourceSet = {
  name: string;
  referencedResourceSet: RelatedProduct[];
};

export interface Variant extends Pick<ProductVariant, 'id' | 'sku'> {
  attributesRaw: Attribute[];
  attributesRawRelatedProducts?: AttributeResourceSet[];
  images?: Pick<CTImage, 'label' | 'url'>[];
  prices: PriceFragment[];
}

export interface ProductData {
  categories: Category[];
  contentList?: string;
  crossSellingProducts?: RelatedProduct[];
  customPageTitle?: LocalizedString;
  description?: string;
  healthTips?: string;
  hiddenFromSearch?: boolean;
  masterVariant: Variant;
  metaDescription?: string;
  name: string;
  testDescription?: string;
  upsellingProducts?: RelatedProduct[];
  variants: Variant[];
}

export interface Product extends Pick<CTProduct, 'id' | 'key'> {
  masterData: {
    current: ProductData;
  };
}

export interface ProductQueryResult {
  data: {
    product: Product;
  };
}

export function productsByCategoryQuery(id: any) {
  return `query ProductsByPrimaryCategory($locale: Locale!) {
    products (where:"masterData(current(categories(id in (\\"${id}\\")))) and masterData(published=true) and (masterData(current(masterVariant(attributes(name=\\"active\\" and value=true)))) or masterData(current(variants(attributes(name=\\"active\\" and value=true)))))") {
     total
     count
     results {
        ...productFields
     }
  }
}

${detailedFragments}
`;
}

export const productQuery = `query Product($locale: Locale!, $key: String) {
  product(key: $key) {
    ...productFields
  }
}

${detailedFragments}
`;

export const productQueryById = `query Product($locale: Locale!, $id: String) {
  product(id: $id) {
    ...productFields
  }
}

${detailedFragments}
`;

export const productQueryBySku = `query Product($locale: Locale!, $sku: String) {
  product(sku: $sku) {
    ...productFields
  }
}

${detailedFragments}
`;
