/* eslint-disable no-shadow */
import { computed } from 'vue';

import { useState } from '@/composables/useState';

export type GladlyChatStatus = 'AVAILABLE' | 'UNAVAILABLE_BUSY' | 'UNAVAILABLE_OFFICE_CLOSED';

let isSuppressed = false;

export function useChat() {
  const { isInitiated, displayChatInfoText, ignoreChatInfoText } = useState('chat', () => ({
    isInitiated: false,
    displayChatInfoText: false,
    ignoreChatInfoText: false,
  }));

  function openChat() {
    window.Gladly?.show();
  }

  function hideChatIcon() {
    const chatWidget = document.getElementById('gladlyChat_container');
    if (chatWidget) {
      chatWidget.style.visibility = 'hidden';
    }
  }

  function showChatIcon() {
    if (isSuppressed) return;
    const chatWidget = document.getElementById('gladlyChat_container');
    if (chatWidget) {
      chatWidget.style.visibility = 'visible';
    }
  }

  function suppressChat() {
    isSuppressed = true;
    hideChatIcon();
  }

  function unsuppressChat() {
    isSuppressed = false;
  }

  const isChatStatusPending = computed(
    () => !displayChatInfoText.value && !ignoreChatInfoText.value,
  );

  const checkChatAvailability = () => {
    const availability = window.Gladly?.getAvailability();
    return availability === 'AVAILABLE';
  };

  function initChat() {
    if (!isInitiated.value) {
      isInitiated.value = true;
      setTimeout(() => {
        ignoreChatInfoText.value = true;
      }, 3000);
      const availability = window.Gladly?.getAvailability();
      if (availability === 'AVAILABLE') {
        displayChatInfoText.value = true;
      }
    }
  }

  return {
    checkChatAvailability,
    hideChatIcon,
    initChat,
    displayChatInfoText,
    openChat,
    isChatStatusPending,
    showChatIcon,
    suppressChat,
    unsuppressChat,
  };
}
