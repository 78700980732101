<script setup lang="ts">
import ChevronIcon from '@/components/base/assets/ChevronIcon.vue';

defineProps<{
  ariaLabel: string;
  direction: 'left' | 'right';
  disabled?: boolean;
}>();
const emit = defineEmits<{ click: [MouseEvent] }>();
</script>

<template>
  <button
    :aria-label="ariaLabel"
    class="flex items-center justify-center w-12 h-12 max-w-full max-h-full p-0 bg-white border border-solid rounded-full shadow-none border-neutral-200"
    :class="disabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'"
    :disabled
    type="button"
    @click="emit('click', $event)"
  >
    <ChevronIcon aria-hidden="true" :direction />
  </button>
</template>
