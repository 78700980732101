<script setup lang="ts">
import { money } from '@nuts/auto-delivery-sdk/dist/utils/format';
import { useVModel } from '@vueuse/core';
import { computed } from 'vue';

import BaseBodyText from '@/components/base/typography/BaseBodyText.vue';
import SmallBodyText from '@/components/base/typography/SmallBodyText.vue';
import { getPrice, NutsProductVariant, PurchaseOptions } from '@/utils/product';

const props = defineProps<{
  isGiftCertificate?: boolean;
  isSelected: boolean;
  modelValue: string | number;
  selections?: PurchaseOptions;
  variant: NutsProductVariant;
  mode?: 'singleLine';
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void;
}>();

const selection = useVModel(props, 'modelValue', emit);

const priceBySku = computed(() => {
  const variants = props.variant.otherVariations?.flatMap(({ variations }) => variations) ?? [];
  return new Map(
    variants.map((v) => [
      props.isGiftCertificate ? v.id : v.sku,
      getPrice(v, props.selections?.autoDelivery?.key),
    ]),
  );
});
const selectedPrice = computed(
  () =>
    priceBySku.value.get(selection.value) ??
    getPrice(props.variant, props.selections?.autoDelivery?.key),
);
</script>

<template>
  <span
    class="relative inline-flex border rounded-md"
    :class="[
      isSelected
        ? 'bg-neutral-100 border-black'
        : 'bg-white border-nuts-neutral-200 hover:bg-zinc-50',
      variant.backordered ? 'border-dashed' : 'border-solid',
      { 'px-3 h-14': !mode },
      { 'p-2 lg:px-3': mode === 'singleLine' },
    ]"
  >
    <label
      class="flex cursor-pointer"
      data-test="variant-option"
      :class="{ 'items-center justify-center w-full': isGiftCertificate }"
    >
      <input
        v-model="selection"
        class="absolute top-0 bottom-0 left-0 right-0 w-full h-full p-0 m-0 rounded-md appearance-none cursor-pointer"
        :id="`variant-${isGiftCertificate ? variant.id : variant.sku}`"
        name="variant"
        type="radio"
        :value="isGiftCertificate ? variant.id : variant.sku"
      />
      <BaseBodyText v-if="isGiftCertificate" class="font-semibold font-sofia-pro">
        {{ money(variant.prices[0].value) }}
      </BaseBodyText>
      <div
        v-else-if="variant.unitName"
        class="flex flex-col w-full"
        :class="{
          'justify-center  gap-y-[0.2rem]': !mode,
          'gap-y-1': mode,
        }"
      >
        <BaseBodyText
          class="title font-sofia-pro"
          :class="[
            {
              'font-normal': mode && !isSelected,
              'font-semibold': !mode || (mode && isSelected),
              'line-clamp-3': mode === 'singleLine',
              truncate: !mode,
            },
          ]"
        >
          {{ `${variant.unitName[0].toUpperCase()}${variant.unitName.slice(1)}` }}
        </BaseBodyText>
        <SmallBodyText
          class="font-normal"
          :class="[
            selectedPrice?.discounted
              ? 'text-nuts-red-800'
              : isSelected
              ? 'text-black'
              : 'text-neutral-600',
          ]"
        >
          {{ money(selectedPrice?.discounted?.value ?? selectedPrice.value) }}
        </SmallBodyText>
      </div>
      <slot name="badge" />
    </label>
  </span>
</template>

<style scoped>
label {
  @apply m-0;
}
</style>
