import { CustomObjectReference } from '@commercetools/platform-sdk';

import { ctApi } from '@/api';

export interface TagReference {
  hidden: boolean;
  name: string;
  showOnProductPage: boolean;
  showOnSearchPage: boolean;
}

export async function productTagReferences(productKey: string) {
  const {
    body: {
      masterData: {
        current: {
          masterVariant: { attributes },
        },
      },
    },
  } = await ctApi
    .products()
    .withKey({ key: productKey })
    .get({ queryArgs: { expand: 'masterData.current.masterVariant.attributes[*].value[*]' } })
    .execute();

  const tagReferences: TagReference[] = attributes
    ?.find(({ name }) => name === 'tagReferences')
    ?.value?.map((tagReference: CustomObjectReference) => tagReference.obj?.value);

  return tagReferences;
}

export async function productTagsByIds(ids: string[]) {
  const quotedIds = ids.map((id) => `"${id}"`).join(',');
  const {
    body: { results },
  } = await ctApi
    .customObjects()
    .withContainer({ container: 'tags' })
    .get({ queryArgs: { limit: 100, where: `id in (${quotedIds})` } })
    .execute();

  const tagReferences: { id: string; value: TagReference }[] = results.map(({ id, value }) => ({
    id,
    value,
  }));

  return tagReferences;
}
