import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import fetchProduct from '@/api/productDetail';
import { NutritionFacts, productNutritionFacts } from '@/api/productNutritionFacts';
import { productTagReferences, TagReference } from '@/api/productTagReferences';
import { useCallback } from '@/composables/useCallback';
import { useFeatureFlags } from '@/composables/useFeatureFlags';
import { Product } from '@/graphql/productQuery';
import { NutsProduct } from '@/utils/product';

export const useProductStore = (productKey: string) =>
  defineStore(`pdp:${productKey}`, () => {
    const rawProduct = ref<Product>();
    const nutritionFacts = ref<NutritionFacts>();
    const tagsReferences = ref<TagReference[]>();

    const getProduct = useCallback(async () => {
      if (rawProduct.value) {
        getProduct.isPending = false;
        return;
      }

      const [
        {
          body: { data: response },
        },
        { HTMLCleaner },
      ] = await Promise.all([fetchProduct(productKey), import('@/utils/html_cleaner')]);

      rawProduct.value = {
        ...response.product,
        masterData: {
          current: {
            ...response.product.masterData.current,
            description: HTMLCleaner.clean(response.product.masterData.current.description),
            healthTips: HTMLCleaner.clean(
              response.product.masterData.current.variants[0]?.attributesRaw?.find(
                (attribute: { name: string }) => attribute.name === 'healthTips',
              )?.value.en,
            ),
            contentList: HTMLCleaner.clean(
              response.product.masterData.current.masterVariant.attributesRaw.find(
                (attribute: { name: string }) => attribute.name === 'contentList',
              )?.value.en,
            ),
            testDescription: HTMLCleaner.clean(
              response.product.masterData.current.masterVariant.attributesRaw.find(
                (attribute: { name: string }) => attribute.name === 'testDescription',
              )?.value.en,
            ),
          },
        },
      };
    });

    const product = computed(() => {
      if (!getProduct.error && !getProduct.isPending && !rawProduct.value) {
        throw new Error('Product information not loaded yet');
      }
      return rawProduct.value
        ? NutsProduct.fromCT(rawProduct.value, useFeatureFlags().flags.testVariantNameDisplay)
        : undefined;
    });

    const getNutritionFacts = useCallback(async () => {
      if (nutritionFacts.value) {
        return;
      }

      const { value } = await productNutritionFacts(productKey);
      nutritionFacts.value = value;
    });

    const getTagReferences = useCallback(async () => {
      if (tagsReferences.value) {
        return;
      }

      tagsReferences.value = await productTagReferences(productKey);
    });

    return {
      getProduct,
      getNutritionFacts,
      getTagReferences,
      nutritionFacts,
      product,
      rawProduct,
      tagsReferences,
    };
  })();
