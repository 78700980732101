<script setup lang="ts">
import kebabCase from 'lodash/kebabCase';
import { computed } from 'vue';

import { Fact } from '@/api/productNutritionFacts';

type Mode = 'nutrient' | 'subnutrient' | 'vitamin';

const props = defineProps<{
  mode: Mode;
  name: string;
  pdv?: Fact['pdv'];
  idPrefix?: string;
  quantity?: Fact['quantity'];
  textWithValue: string;
  unit?: Fact['unit'];
}>();

const attribute = computed(() => kebabCase(props.name));
const pdvDisplay = computed(() => (props.pdv === undefined ? '' : `${props.pdv}%`));
const isSubNutritientMode = computed(() => props.mode === 'subnutrient');
const isNutritientMode = computed(() => props.mode === 'nutrient');

const lastIndexTextWithValue = computed(() => props.textWithValue.split(' ').length - 1);
const nutrientName = computed(() =>
  props.textWithValue.split(' ').slice(0, lastIndexTextWithValue.value).join(' '),
);
const nutrientValue = computed(() => props.textWithValue.split(' ')[lastIndexTextWithValue.value]);
</script>

<template>
  <tr>
    <th
      :class="['text-sm text-left', { 'font-bold': isNutritientMode }]"
      colspan="2"
      :id="isSubNutritientMode ? `${idPrefix}-from-${attribute}` : `${idPrefix}-${attribute}`"
    >
      <p v-if="mode !== 'nutrient'">
        {{ textWithValue }}
      </p>
      <p v-else>
        <span :id="`${idPrefix}-${attribute}-name`"> {{ nutrientName }} </span>
        &nbsp;
        <span class="font-normal" :id="`${idPrefix}-${attribute}-value`">
          {{ nutrientValue }}
        </span>
      </p>
    </th>
    <td
      :class="['text-sm text-right', { 'font-bold': isNutritientMode || isSubNutritientMode }]"
      :headers="
        isSubNutritientMode
          ? `${idPrefix}-daily-value ${idPrefix}-from-${attribute}`
          : `${idPrefix}-daily-value ${idPrefix}-${attribute}`
      "
    >
      {{ pdvDisplay }}
    </td>
  </tr>
</template>

<style scoped>
p {
  @apply m-0;
}
</style>
