<script setup lang="ts">
import { money } from '@nuts/auto-delivery-sdk/dist/utils/format';
import { computed, toRef } from 'vue';

import DiscountBadge from '@/components/base/DiscountBadge.vue';
import AppliedDiscounts from '@/components/pdp/AppliedDiscounts.vue';
import OutOfStockBadge from '@/components/pdp/OutOfStockBadge.vue';
import { useProductDetail } from '@/composables/useProductDetail';
import { getProductDiscountDisplayValueFromPrice } from '@/lib/product/discounts/productDiscounts';
import {
  getPrice,
  NutsProduct,
  NutsProductVariant,
  priceForQuantity,
  PurchaseOptions,
} from '@/utils/product';

const props = defineProps<{
  product: NutsProduct;
  selectedVariant: NutsProductVariant;
  selections: PurchaseOptions;
}>();

const { calculateCostSavings, getRelativeBasePrice } = useProductDetail(toRef(props, 'product'));

const relativeBasePrice = computed(() => getRelativeBasePrice(props.selectedVariant));

const discounts = computed(() => calculateCostSavings(props.selectedVariant, props.selections));

const strikethroughPrice = computed(() => {
  const finalPrice = getPrice(props.selectedVariant, props.selections.autoDelivery?.key);

  if (finalPrice.discounted) return getPrice(props.selectedVariant).value;

  return relativeBasePrice.value.value;
});

const price = computed(() => getPrice(props.selectedVariant, props.selections.autoDelivery?.key));

const finalUnitPrice = computed(() => priceForQuantity(props.selectedVariant, props.selections));
</script>

<template>
  <div class="flex items-center gap-1 md:gap-3">
    <p
      class="text-xl font-semibold display-price font-sofia-pro lg:text-2xl"
      :class="price.discounted ? 'text-nuts-red-800' : 'text-black'"
      data-test="final-price"
      headerTag="p"
    >
      <span v-if="price.discounted" class="sr-only">Discounted price of</span>
      {{ money(finalUnitPrice) }}
    </p>
    <template v-if="!selectedVariant.backordered">
      <p
        v-if="strikethroughPrice && discounts.length"
        class="text-sm line-through text-neutral-500 lg:text-lg"
        data-test="strikethrough-price"
      >
        <span class="sr-only">Original price of</span>
        {{ money(strikethroughPrice) }}
      </p>
      <DiscountBadge
        v-if="price.discounted"
        class="md:hidden"
        :discountDisplayValue="getProductDiscountDisplayValueFromPrice(price)"
        discountType="product"
        size="sm"
      />
      <AppliedDiscounts class="hidden md:inline-flex md:visible" :discounts="discounts" />
    </template>
    <OutOfStockBadge v-else class="hidden md:inline-block md:visible" />
  </div>
</template>

<style lang="scss" scoped>
p {
  @apply mb-0;
}

.display-price {
  @media (min-width: $tailwind-md-min) {
    font-size: 28px;
  }
}
</style>
