<script lang="ts" setup>
import { breakpointsTailwind, useBreakpoints, useTimeoutFn } from '@vueuse/core';
import { ref, watch } from 'vue';

import CaretArrowButton from '@/components/base/CaretArrowButton.vue';
import type { CarouselItem } from '@/components/base/image-gallery/Gallery.vue';
import { DataPromo } from '@/utils/analytics';

const props = defineProps<{
  carouselSlides: CarouselItem[];
  currentIndex: number;
  dataPromo?: DataPromo;
  dataPromoClick?: DataPromo;
  showScroll?: boolean;
  showLeftButton?: boolean;
}>();

const emit = defineEmits(['previous', 'next', 'goTo']);

const miniatures = ref<HTMLElement>();
const translateXVar = ref('0px');

const carouselNewIndex = ref(0);

const isMobile = useBreakpoints(breakpointsTailwind).smaller('lg');

function scrollToMiniature(index: number) {
  const firstChild = miniatures.value?.firstChild as HTMLDivElement;
  const firstChildValue = firstChild?.nextElementSibling as HTMLDivElement;
  const parent = miniatures.value?.parentElement as HTMLDivElement;

  const { marginLeft, marginRight, paddingLeft, paddingRight } =
    window.getComputedStyle(firstChildValue);

  const margin = parseFloat(marginLeft) + parseFloat(marginRight);
  const padding = parseFloat(paddingLeft) + parseFloat(paddingRight);

  const miniatureWidth = firstChildValue.offsetWidth + margin + padding;
  const shift = (index + 1.4) * miniatureWidth - parent.offsetWidth;

  if (isMobile.value) {
    parent?.scrollTo({
      left: Math.max(shift, 0),
      behavior: 'smooth',
    });
  } else {
    translateXVar.value = shift < 0 ? '0px' : `${-shift}px`;
  }
}

const { isPending, start, stop } = useTimeoutFn(() => {
  scrollToMiniature(carouselNewIndex.value);
  emit('goTo', carouselNewIndex.value);
}, 125);

watch(
  () => props.currentIndex,
  (index) => {
    carouselNewIndex.value = index;
    scrollToMiniature(carouselNewIndex.value);
  },
);

function prev() {
  emit('previous');
}

function next() {
  emit('next');
}

function goTo(index: number) {
  emit('goTo', index);
}

function goToDelay(index: number) {
  carouselNewIndex.value = index;
  if (isPending) stop();
  start();
}
</script>
<template>
  <div class="w-full">
    <div
      class="flex flex-row items-center justify-start w-full my-5 miniatures-row shrink-0"
      :data-promo="dataPromo?.type"
      :data-promo-creative="dataPromo?.creative"
      :data-promo-name="dataPromo?.name"
    >
      <div v-if="showScroll" class="w-12 h-12 mr-2">
        <CaretArrowButton
          :ariaLabel="`Previous Image - slide ${currentIndex + 1} of ${carouselSlides.length}`"
          class="text-neutral-500"
          :data-promo="dataPromoClick?.type"
          :data-promo-creative="dataPromoClick?.creative"
          :data-promo-name="dataPromoClick?.name"
          direction="left"
          :disabled="!showLeftButton"
          role="button"
          data-test="previous-image-slide"
          @action="prev"
        />
      </div>
      <div
        class="relative h-full overflow-x-scroll scrollbar-hide sm:overflow-hidden"
        data-test="image-gallery-thumbnails"
        :class="
          showScroll ? '2xl:w-[640px] min-[1440px]:w-[560px] xl:w-[480px] w-[400px]' : 'w-full'
        "
      >
        <ul
          class="absolute left-0 flex-row h-full transition-all duration-[1500ms] translate-x-0 miniatures-carousel pl-4 ws-md:pl-0"
          :class="{
            'translate-x-0': currentIndex === 0,
          }"
          ref="miniatures"
        >
          <li
            v-for="(slide, index) in carouselSlides"
            :key="index"
            :alt="`Image ${index + 1} - ${slide.alt}`"
            class="inline-block h-full p-0 mr-2 overflow-hidden rounded-lg cursor-pointer sm:mr-4 shrink-0 miniatures"
            :class="{
              'border-solid border-black border': index === currentIndex,
            }"
            :data-promo="dataPromoClick?.type"
            :data-promo-creative="dataPromoClick?.creative"
            :data-promo-name="dataPromoClick?.name"
            @click="goTo(index)"
            @mouseover="goToDelay(index)"
          >
            <img
              :src="slide.miniature"
              :alt="`Image ${index + 1} - ${slide.alt}`"
              class="object-contain h-full min-w-full overflow-hidden"
              :data-test="`gallery-miniaure-image-${index + 1}`"
              fetchpriority="low"
            />
          </li>
        </ul>
      </div>
      <div v-if="showScroll" class="w-12 h-12">
        <CaretArrowButton
          :ariaLabel="`Next Image - slide ${currentIndex + 1} of ${carouselSlides.length}`"
          class="text-neutral-500"
          :data-promo="dataPromoClick?.type"
          :data-promo-creative="dataPromoClick?.creative"
          :data-promo-name="dataPromoClick?.name"
          direction="right"
          role="button"
          data-test="next-image-slide"
          @action="next"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>
.miniatures {
  @apply 2xl:w-28 min-[1440px]:w-24 xl:w-20 w-16;
}
.miniatures-carousel {
  transform: translateX(v-bind(translateXVar));
}
.miniatures-row {
  @apply 2xl:h-20 min-[1440px]:h-16 xl:h-14 h-12;
}
</style>
