<script setup lang="ts">
import { computed, ComputedRef } from 'vue';

import { FactSet } from '@/api/productNutritionFacts';
import { TagReference } from '@/api/productTagReferences';
import certifiedGlutenFree from '@/assets/pdp/product-facts/certified/gluten-free.png';
import certifiedKosher from '@/assets/pdp/product-facts/certified/kosher.svg';
import certifiedKosherDairy from '@/assets/pdp/product-facts/certified/kosher-dairy.svg';
import certifiedKosherPareve from '@/assets/pdp/product-facts/certified/kosher-parve.svg';
import certifiedOrganic from '@/assets/pdp/product-facts/certified/organic.png';
import glutenFree from '@/assets/pdp/product-facts/gluten-free.svg';
import keto from '@/assets/pdp/product-facts/keto.svg';
import noSugarAdded from '@/assets/pdp/product-facts/no-sugar-added.svg';
import noSulfurDioxide from '@/assets/pdp/product-facts/no-sulfur-dioxide.svg';
import saltFree from '@/assets/pdp/product-facts/salt-free.svg';
import sugarFree from '@/assets/pdp/product-facts/sugar-free.svg';
import unsalted from '@/assets/pdp/product-facts/unsalted.svg';
import Header5 from '@/components/base/typography/Header5.vue';
import LargeBodyText from '@/components/base/typography/LargeBodyText.vue';
import SmallBodyText from '@/components/base/typography/SmallBodyText.vue';
import { Icon } from '@/composables/useNutritionFacts';

const props = defineProps<{
  perServingNutritionFacts?: FactSet;
  tagsReferences?: TagReference[];
}>();

const allCertifications: Icon[] = [
  {
    id: 'kosher',
    name: 'Kosher',
    imageSrc: certifiedKosher,
    modalText:
      'This product is certified kosher with rabbinical supervision by OK Kosher Certification.',
    url: '/kosher/',
  },
  {
    id: 'kosher pareve',
    name: 'Kosher Pareve',
    imageSrc: certifiedKosherPareve,
    modalText:
      'Pareve is the Yiddish term for foods containing no meat or dairy ingredients. According to the Jewish dietary laws, whereas meat and milk products may not be cooked or eaten together, pareve foods are considered neutral and may be eaten with either meat or dairy dishes.',
  },
  {
    id: 'certified gluten-free',
    name: 'Gluten-Free',
    imageSrc: certifiedGlutenFree,
    modalText:
      'Certified by the Gluten-Free Food Program (GFFP); any foods that carry the label “gluten-free,” “no gluten,” “free of gluten,” or “without gluten” must contain less than 5 parts per million (ppm) of gluten.',
    url: '/gluten-free',
  },
  {
    id: 'organic',
    name: 'Organic',
    imageSrc: certifiedOrganic,
    modalText: `Certified by the California Certified Organic Farmers (CCOF) certifying agency; according to the USDA, a product can be called organic if it's certified to have grown on soil with no prohibited substances applied for three years before harvest.`,
  },
  {
    id: 'kosher dairy',
    name: 'Kosher Dairy',
    imageSrc: certifiedKosherDairy,
    modalText:
      'All foods derived from, or containing, milk are classified as dairy, including milk, butter, yogurt and all cheese – hard, soft and cream. Even a trace amount of dairy can cause a food to be considered dairy.',
  },
];

const allGenerals: Icon[] = [
  {
    id: 'gluten-free',
    name: 'Gluten-Free',
    imageSrc: glutenFree,
    modalText:
      'According to the FDA, any foods that carry the label “gluten-free,” “no gluten,” “free of gluten,” or “without gluten” must contain less than 20 parts per million (ppm) of gluten.',
    url: '/gluten-free',
  },
  {
    id: 'sugar-free',
    name: 'Sugar Free',
    imageSrc: sugarFree,
    modalText:
      'The FDA defines "sugar-free" as a claim that may be used on a food that contains less than 0.5 g of sugars – per reference amount customarily consumed and per labeled serving.',
  },
  {
    id: 'no-sugar-added',
    name: 'No Sugar Added',
    imageSrc: noSugarAdded,
    modalText:
      'According to the FDA, when manufacturers claim a food has “no added sugars,” it cannot be processed with any sugar or sugar-containing ingredients, though it can have sugar alcohol or artificial sweeteners. Products without added sugar can contain naturally occurring sugar.',
  },
  {
    id: 'salt-free',
    name: 'Salt Free',
    imageSrc: saltFree,
    modalText:
      'According to the FDA, "salt-free" refers to a product with less than 5 mg of salt per serving.',
  },
  {
    id: 'unsalted',
    name: 'Unsalted',
    imageSrc: unsalted,
    modalText:
      'According to the FDA, "unsalted" refers to a product with no salt added during processing; it may not be salt-free or sodium-free unless stated.',
  },
  {
    id: 'no-sulfur-dioxide',
    name: 'No Sulfur Dioxide',
    imageSrc: noSulfurDioxide,
    modalText:
      'This product does not contain Sulfur Dioxide. The FDA says this substance is generally safe when used in accordance with good manufacturing practices; except that it is not used in meats; in food recognized as a source of vitamin B1; on fruits or vegetables intended to be served raw to consumers or sold raw to consumers, or to be presented to consumers as fresh.',
  },
  {
    id: 'keto',
    name: 'Keto',
    imageSrc: keto,
    modalText:
      'The Ketogenic Diet, or keto, is a low-carb, moderate protein, high-fat diet. FDA does not regulate the terms “keto” or “ketogenic” on food labels.',
  },
];

const certifications = computed(() => {
  const actualCertifications = allCertifications.filter((certification) =>
    props.tagsReferences?.find(({ name }) => name === certification.id),
  );

  const kosherIndex = actualCertifications.findIndex(({ id }) => id === 'kosher');
  const kosherPareveIndex = actualCertifications.findIndex(({ id }) => id === 'kosher pareve');
  if (kosherIndex > -1 && kosherPareveIndex > -1) {
    actualCertifications.splice(kosherIndex, 1);
  }

  const kosherDairyIndex = actualCertifications.findIndex(({ id }) => id === 'kosher dairy');
  if (kosherIndex > -1 && kosherDairyIndex > -1 && kosherPareveIndex === -1) {
    actualCertifications.splice(kosherIndex, 1);
  }

  return actualCertifications;
});

const generals = computed(() => {
  const actualGenerals = allGenerals.filter((general) =>
    props.tagsReferences?.find(({ name }) => name === general.id),
  );

  const glutenFreeIndex = actualGenerals.findIndex(({ id }) => id === 'gluten-free');
  const certifiedGlutenFreeIndex = certifications.value.findIndex(
    ({ id }) => id === 'certified gluten-free',
  );
  if (glutenFreeIndex > -1 && certifiedGlutenFreeIndex > -1) {
    actualGenerals.splice(glutenFreeIndex, 1);
  }

  const saltFreeIndex = actualGenerals.findIndex(({ id }) => id === 'salt-free');
  const unsaltedIndex = actualGenerals.findIndex(({ id }) => id === 'unsalted');
  if (saltFreeIndex > -1 && unsaltedIndex > -1) {
    actualGenerals.splice(saltFreeIndex, 1);
  }

  return actualGenerals;
});

const nutritionFacts: ComputedRef<Icon[]> = computed(() => {
  const list = [];

  if (!props.perServingNutritionFacts) return [];

  const { protein, dietaryFiber, vitaminA, vitaminC, vitaminD, calcium, iron, potassium } =
    props.perServingNutritionFacts;

  if (protein?.pdv || protein?.quantity)
    list.push({
      id: 'protein',
      name: 'Protein',
      modalText:
        'General recommendations are to consume 15–30 grams of protein at each meal. Studies show higher intakes — those more than 40 grams — in one sitting are no more beneficial than the recommended 15–30 grams at one time.',
      pdv: protein.pdv,
      quantity: protein.quantity,
      unit: protein.unit,
    });

  if (dietaryFiber?.pdv || dietaryFiber?.quantity)
    list.push({
      id: 'dietary-fiber',
      name: 'Dietary Fiber',
      modalText:
        'In order for a product to be labeled “high fiber,” it must contain 5 grams or more of dietary fiber per serving.',
      pdv: dietaryFiber.pdv,
      quantity: dietaryFiber.quantity,
      unit: dietaryFiber.unit,
    });

  if (vitaminA?.pdv || vitaminA?.quantity)
    list.push({
      id: 'vitamin-a',
      name: 'Vitamin A',
      modalText:
        'The recommended dietary allowance (RDA) is 900 micrograms (mcg) for males, 700 mcg for females, and 300–600 mcg for children and adolescents.',
      pdv: vitaminA.pdv,
      quantity: vitaminA.quantity,
      unit: vitaminA.unit,
    });

  if (vitaminC?.pdv || vitaminC?.quantity)
    list.push({
      id: 'vitamin-c',
      name: 'Vitamin C',
      modalText: 'The current daily value (DV) for vitamin C is 90 mg for men and 75mg for women.',
      pdv: vitaminC.pdv,
      quantity: vitaminC.quantity,
      unit: vitaminC.unit,
    });

  if (vitaminD?.pdv || vitaminD?.quantity)
    list.push({
      id: 'vitamin-d',
      name: 'Vitamin D',
      modalText:
        'The daily value (DV) for vitamin D is 800 IU (20 mcg). Levels of 50 nmol/L (20 ng/mL) or above are adequate for most people for bone and overall health.',
      pdv: vitaminD.pdv,
      quantity: vitaminD.quantity,
      unit: vitaminD.unit,
    });

  if (calcium?.pdv || calcium?.quantity)
    list.push({
      id: 'calcium',
      name: 'Calcium',
      modalText:
        'If the "percent Daily Value" (%DV) of calcium is 20 percent or more per serving, the food is high in calcium. If the %DV is between 10 and 19 percent, the food is a good source of calcium.',
      pdv: calcium.pdv,
      quantity: calcium.quantity,
      unit: calcium.unit,
    });

  if (iron?.pdv || iron?.quantity)
    list.push({
      id: 'iron',
      name: 'Iron',
      modalText:
        'General recommendations are to consume the following amounts of iron: 18 milligrams (mg) a day for women and people assigned female at birth (AFAB). 27 mg a day for pregnant people. 9 mg a day for lactating people. 8 mg a day for men and people assigned male at birth.',
      pdv: iron.pdv,
      quantity: iron.quantity,
      unit: iron.unit,
    });

  if (potassium?.pdv || potassium?.quantity)
    list.push({
      id: 'potassium',
      name: 'Potassium',
      modalText:
        'A food that is considered “high-potassium” generally has 200 mg or more potassium per serving.',
      pdv: potassium.pdv,
      quantity: potassium.quantity,
      unit: potassium.unit,
    });

  return list;
});

const icons = computed(() => {
  const iconsList: Icon[] = generals.value;
  if (iconsList.length < 4) return iconsList.concat(nutritionFacts.value).slice(0, 4);
  return iconsList;
});

const nutritionFactsValue = ({ pdv, quantity, unit }: Icon) => {
  if (!quantity || quantity === 0 || !unit) {
    return `${pdv}%`;
  }

  return `${quantity}${unit}`;
};
</script>

<template>
  <div v-if="icons.length > 0" class="mt-4">
    <Header5>Highlights:</Header5>
    <div class="flex flex-wrap w-full gap-x-2">
      <div
        v-for="(icon, index) in icons.slice(0, 4)"
        :key="index"
        class="px-3 py-2 mt-2 bg-nuts-amber-400 rounded-3xl"
      >
        <LargeBodyText class="font-normal-overwrite whitespace-nowrap">
          <span v-if="icon.quantity || icon.pdv">
            {{ nutritionFactsValue(icon) }}
          </span>
          {{ icon.name }}
        </LargeBodyText>
      </div>
    </div>
  </div>
  <div v-if="certifications.length > 0" class="mt-4">
    <Header5>Certifications:</Header5>
    <div class="flex flex-wrap w-full gap-x-4">
      <div v-for="(icon, index) in certifications.slice(0, 4)" :key="index">
        <div class="flex flex-col items-center justify-center mt-2">
          <img :alt="icon.name" class="w-8 h-8 lg:w-11 lg:h-11" :src="icon.imageSrc" />
          <SmallBodyText class="mt-1 font-semibold">
            {{ icon.name }}
          </SmallBodyText>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.font-normal-overwrite {
  @apply font-normal;
}
</style>
