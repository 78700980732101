<script setup lang="ts">
import UnstyledButton from '@/components/base/UnstyledButton.vue';

defineProps<{ slideCount: number }>();
const currentIndex = defineModel<number>({ required: true });
</script>

<template>
  <div class="flex justify-center gap-2">
    <UnstyledButton
      v-for="(image, index) in slideCount"
      :aria-label="`Go to image ${index + 1}`"
      class="w-1.5 h-1.5 transition-colors duration-300 rounded-full"
      :class="index === currentIndex ? 'bg-neutral-500' : 'bg-neutral-300'"
      :key="image"
      @click="currentIndex = index"
    />
  </div>
</template>
