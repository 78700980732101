<script setup lang="ts">
import { required } from '@vee-validate/rules';
import { Form } from 'vee-validate';
import { computed, ref } from 'vue';

import FormInput from '@/components/base/form/FormInput.vue';
import ThemedButton from '@/components/base/ThemedButton.vue';
import { FlexibleMessageValidator, useForm as formUtils } from '@/composables/useForm';
import { validationRulesForCountry } from '@/utils/address';

const props = defineProps<{
  disabled: boolean;
  error?: Error;
}>();

const emit = defineEmits<{
  (e: 'update', postalCode: string): void;
}>();

const postalCode = ref('');

function onUpdateClick() {
  emit('update', postalCode.value);
}

const inputAttributes = computed(() => ({
  name: 'postal-code',
  placeholder: 'ZIP Code',
  disabled: props.disabled,
}));

const { errorMessages, validatorFailed } = formUtils();
const { postalCode: postalCodeUS } = validationRulesForCountry('US');
const { postalCode: postalCodeCA } = validationRulesForCountry('CA');
const validatePostalCode: FlexibleMessageValidator<string> = (value) => {
  if (!required(value)) {
    return errorMessages.zipCode.required;
  }
  if (!postalCodeUS.regex.test(value) && !postalCodeCA.regex.test(value)) {
    return errorMessages.zipCode.regex;
  }
  return true;
};
</script>

<template>
  <Form @submit="onUpdateClick" v-slot="{ meta: formMeta }">
    <div class="flex">
      <FormInput
        v-model="postalCode"
        aria-label="zipcode"
        class="w-full"
        :error="!!error"
        :inputAttributes="inputAttributes"
        :helperText="error ? `Error: ${error?.message}` : ''"
        showLabel
        showPlaceholder
        :validator="validatePostalCode"
      />
      <ThemedButton
        class="ml-2"
        data-test="update-postal-code-button"
        :disabled="disabled || (formMeta.touched && validatorFailed(formMeta))"
        theme="gray"
        @click="onUpdateClick"
      >
        Update
      </ThemedButton>
    </div>
  </Form>
</template>
